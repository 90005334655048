var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        SP.changeHash = function (hash) { return location.hash = hash; };
        SP.selectMainContents = function (selector) {
            $('[role=main]').attr('hidden', 'true').filter(selector).removeAttr('hidden');
            $(window).scrollTop(0);
        };
        SP.isHeaderSearch = function () { return location.hash.match(/#overlay/) ? true : false; };
        SP.isNotification = function (hash) {
            if (hash === void 0) { hash = location.hash; }
            return hash.indexOf(SP.Notification.hash) !== -1;
        };
        SP.isIdeaEdit = function () { return location.pathname.match(/idea\/.*\/edit/) ? true : false; };
        SP.isOtherHash = function () { return location.hash.match(/#.+/) ? true : false; };
        SP.isPhotoUpload = function (hash) {
            if (hash === void 0) { hash = location.hash; }
            return hash.indexOf(SP.PhotoUploadView.hash) !== -1;
        };
        var getView = function () {
            var hash = location.hash;
            if (SP.isHeaderSearch())
                return Other;
            if (SP.isNotification(hash))
                return SP.Notification;
            if (SP.isIdeaEdit())
                return getIdeaEditView(hash);
            if (SP.isPhotoUpload())
                return SP.PhotoUploadView;
            if (SP.isOtherHash())
                return Other;
            return Main;
        };
        var getIdeaEditView = function (hash) {
            if (hash === void 0) { hash = location.hash; }
            if (hash.indexOf(SP.IdeaAddTextElementView.hash) !== -1)
                return SP.IdeaAddTextElementView;
            if (hash.indexOf(SP.IdeaAddPhotoElementView.hash) !== -1)
                return SP.IdeaAddPhotoElementView;
            if (hash.indexOf(SP.IdeaAddLinkElementView.hash) !== -1)
                return SP.IdeaAddLinkElementView;
            if (hash.indexOf(SP.IdeaAddAffiliateElementView.hash) !== -1)
                return SP.IdeaAddAffiliateElementView;
            if (hash.indexOf(SP.IdeaAddMovieElementView.hash) !== -1)
                return SP.IdeaAddMovieElementView;
            if (hash.indexOf(SP.IdeaAddSnsElementView.hash) !== -1)
                return SP.IdeaAddSnsElementView;
            if (hash.indexOf(SP.IdeaEditInfoKeywordsView.hash) !== -1)
                return SP.IdeaEditInfoKeywordsView;
            if (hash.indexOf(SP.IdeaEditMainPhotoView.hash) !== -1)
                return SP.IdeaEditMainPhotoView;
            return SP.IdeaEditView;
        };
        SP.delegateHashChangeEvent = function () { return $(window).on('hashchange', function () { return getView().show(); }); };
        var Main = (function () {
            function Main() {
            }
            Main.show = function () {
                $('[role=main]').attr('hidden', 'true').not('#' + SP.Notification.hash + ', '
                    + '#' + SP.IdeaAddTextElementView.hash + ', '
                    + '#' + SP.IdeaAddPhotoElementView.hash + ', '
                    + '#' + SP.IdeaAddLinkElementView.hash + ', '
                    + '#' + SP.IdeaAddAffiliateElementView.hash + ', '
                    + '#' + SP.IdeaAddMovieElementView.hash + ', '
                    + '#' + SP.IdeaAddSnsElementView.hash + ', '
                    + '#' + SP.PhotoUploadView.hash).removeAttr('hidden');
            };
            return Main;
        }());
        var Other = (function () {
            function Other() {
            }
            Other.show = function () { };
            return Other;
        }());
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
