var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var UserSettingView = (function (_super) {
            __extends(UserSettingView, _super);
            function UserSettingView() {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.$nickname = _this.$body.find('input[name=nickname]');
                _this.$intro = _this.$body.find('textarea[name=introduction]');
                _this.$website = _this.$body.find('input[name=website]');
                _this.$instagramUrl = _this.$body.find('input[name=instagram_url]');
                _this.$twitterUrl = _this.$body.find('input[name=twitter_url]');
                _this.$facebookUrl = _this.$body.find('input[name=facebook_url]');
                _this.delegateEvent();
                _this.nameCounter = new Fily.InputCounter(_this.$nickname, _this.$body.find('.js-counter--nickname'));
                _this.introCounter = new Fily.InputCounter(_this.$intro, _this.$body.find('.js-counter--introduction'));
                _this.websiteCounter = new Fily.InputCounter(_this.$website, _this.$body.find('.js-counter--website'));
                _this.instagramCounter = new Fily.InputCounter(_this.$instagramUrl, _this.$body.find('.js-counter--instagram'));
                _this.twitterCounter = new Fily.InputCounter(_this.$twitterUrl, _this.$body.find('.js-counter--twitter'));
                _this.facebookCounter = new Fily.InputCounter(_this.$facebookUrl, _this.$body.find('.js-counter--facebook'));
                return _this;
            }
            UserSettingView.prototype.events = function () {
                var _this = this;
                return {
                    'click / .js-save-profile': function (e) {
                        Fily.showFullLoading();
                        Fily.Ngword.checkInput([_this.$nickname, _this.$intro])
                            .done(function () {
                            UserSettingView.check = true;
                            $(e.currentTarget).parents('form').submit();
                        })
                            .always(function () { return Fily.hideFullLoading(); });
                    }
                };
            };
            UserSettingView.check = false;
            return UserSettingView;
        }(Fily.BaseView));
        SP.UserSettingView = UserSettingView;
        var UserSettingOtherView = (function (_super) {
            __extends(UserSettingOtherView, _super);
            function UserSettingOtherView() {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.$alias = $('input[name=alias]', _this.$body);
                _this.$aliasField = _this.$alias.parents('.Field');
                _this.delegateEvent();
                _this.aliasCounter = new Fily.InputCounter(_this.$alias, $('.js-counter--alias', _this.$body), function (isOver) {
                    if (isOver) {
                        _this.$aliasField.addClass('is-error');
                    }
                    else {
                        _this.$aliasField.removeClass('is-error');
                    }
                });
                return _this;
            }
            UserSettingOtherView.prototype.events = function () {
                return {
                    'submit / #js-changeAliasForm': function (e) {
                        if (!window.confirm('「マイページのURL」は一度変更すると180日間変更することができません。\n本当に変更しますか？')) {
                            e.preventDefault();
                        }
                    }
                };
            };
            return UserSettingOtherView;
        }(Fily.BaseView));
        SP.UserSettingOtherView = UserSettingOtherView;
        var UserSettingAdditionalView = (function (_super) {
            __extends(UserSettingAdditionalView, _super);
            function UserSettingAdditionalView() {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.delegateEvent();
                _this.$zipcode = $('input[name=zip_code]', _this.$body);
                _this.$prefecture = $('select[name=prefecture]', _this.$body);
                _this.$city = $('input[name=address1]', _this.$body);
                _this.ZipSearch = new Fily.ZipSearch();
                new Fily.InputCounter($('.js-input--company'), $('.js-counter--company'));
                new Fily.InputCounter($('.js-input--area'), $('.js-counter--area'));
                new Fily.InputCounter($('.js-input--license'), $('.js-counter--license'));
                new Fily.InputCounter($('.js-input--organization'), $('.js-counter--organization'));
                if (!Fily.Config.isIOSWebview) {
                    new Fily.InputCounter($('.js-input--department'), $('.js-counter--department'));
                    new Fily.InputCounter($('.js-input--manager'), $('.js-counter--manager'));
                }
                return _this;
            }
            UserSettingAdditionalView.prototype.events = function () {
                var _this = this;
                return {
                    'click / .js-zipSearch': function (e) {
                        var $button = $(e.currentTarget);
                        _this.ZipSearch.request(_this.$zipcode.val())
                            .done(function (addresslist) {
                            Fily.Field.Err.hide(_this.$zipcode);
                            var pref = _this.ZipSearch.getPrefecture(addresslist);
                            var city = _this.ZipSearch.getCity(addresslist);
                            var town = _this.ZipSearch.getTown(addresslist);
                            var address = '';
                            if (pref.length !== 1) {
                                _this.$prefecture.val('');
                                _this.$city.val('');
                            }
                            else {
                                _this.$prefecture.find('option')
                                    .filter(function (i, el) { return $(el).html() === pref[0]; })
                                    .prop('selected', 'selected');
                            }
                            if (city.length !== 1) {
                                _this.$city.val('');
                                return;
                            }
                            else {
                                address += city[0];
                                if (town.length === 1)
                                    address += town[0];
                                _this.$city.val(address);
                            }
                        })
                            .fail(function (err) {
                            if (err.code === Fily.ERR.CODE.INPUT_INVALID) {
                                $button.addClass('is-error');
                                Fily.Field.Err.show(_this.$zipcode, err.code, ['郵便番号']);
                                setTimeout(function () {
                                    $button.removeClass('is-error');
                                }, 500);
                            }
                        });
                    },
                    'keydown / input': function (e) {
                        if (e.keyCode === 13)
                            e.preventDefault();
                    },
                    'change blur / #js-mainJob': function (e) {
                        if (e.target.value === '') {
                            $('.js-subJob').prop('disabled', true);
                        }
                        else {
                            $('.js-subJob').prop('disabled', false);
                        }
                    }
                };
            };
            return UserSettingAdditionalView;
        }(Fily.BaseView));
        SP.UserSettingAdditionalView = UserSettingAdditionalView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
