var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var PageLinkView = (function (_super) {
            __extends(PageLinkView, _super);
            function PageLinkView() {
                return _super.call(this, {}) || this;
            }
            PageLinkView.prototype.events = function () {
                return {
                    'click / .js-pageTop': 'movePageTop'
                };
            };
            PageLinkView.prototype.delegateEvent = function () {
                _super.prototype.delegateEvent.call(this);
                var hash = encodeURI(location.hash);
                if (hash === '' || hash === '#pageTop') {
                    return false;
                }
                PageLinkView.smoothScroll({
                    target: hash,
                    adjustVal: -120
                });
            };
            PageLinkView.prototype.movePageTop = function () {
                PageLinkView.smoothScroll({
                    target: 'body'
                });
            };
            PageLinkView.smoothScroll = function (options) {
                var scrollBar = options.scrollTarget || 'body, html';
                var adjustVal = options.adjustVal || 0;
                var $target = $(options.target);
                if ($target.length < 1)
                    return;
                var scrollTop = $target.offset().top;
                $(scrollBar).animate({
                    scrollTop: scrollTop + adjustVal
                }, 350);
            };
            return PageLinkView;
        }(Fily.BaseView));
        SP.PageLinkView = PageLinkView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
