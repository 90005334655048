var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var PhotoEditView = (function (_super) {
            __extends(PhotoEditView, _super);
            function PhotoEditView() {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.keywordTpl = new Fily.MustacheTpl('keyword/edit/item');
                _this.$body = $('.js-Photo');
                _this.delegateEvent();
                _this.photoId = _this.$body.data('photo-id');
                _this.galleryId = _this.$body.data('gallery-id');
                _this.$description = $('textarea[name=description]', _this.$body);
                _this.descriptionCounter = new Fily.InputCounter(_this.$description, $('.js-counter--description'));
                _this.$creditTitle = $('input[name=credit]', _this.$body);
                _this.creditTitleCounter = new Fily.InputCounter(_this.$creditTitle, $('.js-counter--credit'));
                _this.$creditUrl = $('input[name=crediturl]', _this.$body);
                _this.creditUrlCounter = new Fily.InputCounter(_this.$creditUrl, $('.js-counter--crediturl'));
                _this.toggleKeywordEmptyMessage();
                return _this;
            }
            PhotoEditView.prototype.events = function () {
                var _this = this;
                return {
                    'click / .js-delete--photo': function () {
                        if (window.confirm('このフォトを削除しますが、よろしいですか？')) {
                            Fily.Api.Photo.remove(_this.photoId).done(function () {
                                if (Fily.Config.isIOSWebview) {
                                    location.href = 'limia-bridge://pop?from=photo_edit';
                                }
                                else {
                                    location.href = '/user/' + Fily.Myself.alias + '/photo/';
                                }
                            });
                        }
                    },
                    'click / .js-save--photo': 'edit',
                    'click / .js-keyword-add': 'addKeyword',
                    'click / .js-keyword-remove': 'removeKeyword',
                };
            };
            PhotoEditView.prototype.edit = function () {
                var _this = this;
                var invalid = false;
                if (!this.descriptionCounter.checkLength()) {
                    Fily.Field.Err.show(this.$description, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['コメント', this.descriptionCounter.maxLength.toString()]);
                    invalid = true;
                }
                else {
                    Fily.Field.Err.hide(this.$description);
                }
                if (!this.creditTitleCounter.checkLength()) {
                    Fily.Field.Err.show(this.$creditTitle, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['クレジット', this.creditTitleCounter.maxLength.toString()]);
                    invalid = true;
                }
                else {
                    Fily.Field.Err.hide(this.$creditTitle);
                }
                var creditUrl = this.$creditUrl.val();
                if (!this.creditUrlCounter.checkLength()) {
                    Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['出典（URL）', this.creditUrlCounter.maxLength.toString()]);
                    invalid = true;
                }
                else if (creditUrl && !Fily.Util.isUrl(creditUrl)) {
                    Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.INPUT_INVALID, ['出典（URL）']);
                    invalid = true;
                }
                else if (Fily.Denied.isDeniedPhotoUrl(creditUrl)) {
                    Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.DENIED_PHOTO_URL);
                    invalid = true;
                }
                else {
                    Fily.Field.Err.hide(this.$creditUrl);
                }
                if (invalid)
                    return;
                Fily.showFullLoading();
                Fily.Api.Photo.edit(this.photoId, {
                    gallery_id: $('select[name=gallery]', this.$body).val(),
                    description: this.$description.val(),
                    credit_title: this.$creditTitle.val(),
                    credit_url: creditUrl,
                    keywords: this.keywords()
                }).done(function (res) {
                    location.href = $('.js-save--photo', _this.$body).data('href');
                }).fail(function () { return Fily.Ngword.checkInput([_this.$description, _this.$creditTitle]); })
                    .always(function () { return Fily.hideFullLoading(); });
            };
            PhotoEditView.prototype.addKeyword = function () {
                var _this = this;
                var $input = $('input[name=keyword]');
                var keyword = $input.val().trim();
                if (!keyword)
                    return;
                if (Fily.Util.Str.isHalfwidthSymbol(keyword[0])) {
                    Fily.Field.Err.show($input, Fily.ERR.CODE.INPUT_KEYWORD_INVALID);
                    return;
                }
                else {
                    Fily.Field.Err.hide($input);
                }
                var MAX_KEYWORD_LENGTH = $input.data('maxlength');
                if (keyword.length > MAX_KEYWORD_LENGTH) {
                    Fily.Field.Err.show($input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['キーワード', MAX_KEYWORD_LENGTH]);
                    return;
                }
                var MAX_KEYWORD_NUM = 30;
                if (this.keywords().length >= MAX_KEYWORD_NUM) {
                    Fily.Field.Err.show($input, Fily.ERR.CODE.SET_MAX_ITEM_INVALID, ['キーワード', '30ワード']);
                    return;
                }
                this.keywordTpl.load().done(function () {
                    $('.js-keyword-list').append(_this.keywordTpl.render({ name: keyword }));
                    _this.toggleKeywordEmptyMessage();
                    $input.val('');
                });
            };
            PhotoEditView.prototype.removeKeyword = function (event) {
                $(event.currentTarget).remove();
                this.toggleKeywordEmptyMessage();
            };
            PhotoEditView.prototype.toggleKeywordEmptyMessage = function () {
                $('.js-keyword-empty').toggle(this.keywords().length == 0);
            };
            PhotoEditView.prototype.keywords = function () {
                return Array.prototype.map.call($('.js-keyword-remove'), function (dom) { return $(dom).data('keyword-name'); });
            };
            return PhotoEditView;
        }(Fily.BaseView));
        SP.PhotoEditView = PhotoEditView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
