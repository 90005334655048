var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var PresentView = (function (_super) {
            __extends(PresentView, _super);
            function PresentView() {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.$body = $('#js-Present');
                _super.prototype.delegateEvent.call(_this);
                _this.presentId = _this.$body.data('present-id');
                _this.myselfId = _this.$body.data('myself-id');
                _this.userId = $('.js-author').data('user-id');
                _this.userAlias = $('.js-author').data('user-alias');
                _this.questionBlock = $('.js-questionBlock');
                _this.questionTextareaBlock = $('.js-questionTextareaBlock');
                return _this;
            }
            PresentView.prototype.events = function () {
                var _this = this;
                return {
                    'click / input[name^=question]': function (e) {
                        var $target = $(e.currentTarget);
                        if ($target.attr('type') == 'radio') {
                            $target.parents('.js-fromWrap').find('.js-text').prop('disabled', true);
                            $target.closest('div').find('.js-text').prop('disabled', false);
                        }
                        if ($target.attr('type') == 'checkbox') {
                            if ($target.closest('div').find('.js-text').prop('disabled')) {
                                $target.closest('div').find('.js-text').prop('disabled', false);
                            }
                            else {
                                $target.closest('div').find('.js-text').prop('disabled', true);
                            }
                        }
                    },
                    'change / .js-selectQuestion': function (e) {
                        var $target = $(e.currentTarget);
                        $('.js-selectQuetionInput .js-text').val('');
                        if ($target.children('option:selected').data('has-freetext') === 1) {
                            $('.js-selectQuetionInput').show();
                        }
                        else {
                            $('.js-selectQuetionInput').hide();
                        }
                    },
                    'click / .js-presentEntry': function (e) {
                        var $target = $(e.currentTarget);
                        Fily.Api.Present.entry(_this.presentId, _this.myselfId).done(function (res) {
                            if (res['result'] == true) {
                                $target.addClass('is-disabled').removeAttr('data-button').text('応募済み').removeClass('js-presentEntry');
                                $('.js-presentEntryText').text('当選された方には、メールでお知らせします');
                                $('#js-obiSuccess').addClass('is-active');
                                $('.js-obiMessage').html('応募が完了しました');
                                Fily.GA.sendEvent('プレゼント', 'プレゼントID:' + _this.presentId, 'アンケートなし応募', 1);
                                Fily.Api.User.follow(_this.userAlias, _this.userId).done(function () {
                                    $('.js-author .js-followButton').addClass('is-following');
                                });
                            }
                        });
                    },
                    'click / .js-questionAnswer': function () {
                        var answers = [];
                        _this.questionBlock.each(function (i, element) {
                            var bodyId = null;
                            var freetext = '';
                            if ($(element).find('input').prop('checked')) {
                                bodyId = $(element).attr('data-body-id');
                                if ($(element).find('.js-text').val()) {
                                    freetext = $(element).find('.js-text').val();
                                }
                            }
                            else if ($(element).find('select').val() > 0) {
                                bodyId = $(element).find('select').val();
                                if ($(element).find('.js-text').val()) {
                                    freetext = $(element).find('.js-text').val();
                                }
                            }
                            if (bodyId > 0) {
                                answers.push({ 'id': bodyId, 'freetext': freetext });
                            }
                        });
                        _this.questionTextareaBlock.each(function (i, element) {
                            if ($(element).find('textarea').val() != undefined || $(element).find('textarea').val() != '') {
                                var bodyId = null;
                                var freetext = '';
                                bodyId = $(element).attr('data-body-id');
                                freetext = $(element).find('textarea').val();
                                answers.push({ 'id': bodyId, 'freetext': freetext });
                            }
                        });
                        Fily.Api.Present.answers(_this.presentId, _this.myselfId, { answers: answers }).done(function (res) {
                            if (res.result.has_error == false) {
                                $('.js-questionAnswer').addClass('is-disabled').removeAttr('data-button').text('応募済み').removeClass('js-questionAnswer');
                                $('.js-presentEntryText').text('当選された方には、メールでお知らせします');
                                $('#js-obiSuccess').addClass('is-active');
                                $('.js-obiMessage').html('応募が完了しました');
                                Fily.GA.sendEvent('プレゼント', 'プレゼントID:' + _this.presentId, 'アンケート回答応募', 1);
                                setTimeout(function () {
                                    location.href = '/present/' + _this.presentId + '/';
                                }, 1000);
                            }
                            for (var i = 0; i < res.result.heads.length; i++) {
                                if (res.result.heads[i].error_code > 0) {
                                    $('.js-hasError:nth-child(' + (i + 1) + ')').addClass('is-error');
                                    $('.js-hasError:nth-child(' + (i + 1) + ') .js-errorMessage').html(res.result.heads[i].error_message);
                                }
                                else if (res.result.heads[i].error_code === 0) {
                                    $('.js-hasError:nth-child(' + (i + 1) + ')').removeClass('is-error');
                                }
                                var bodies = res.result.heads[i].bodies;
                                for (var j = 0; j < bodies.length; j++) {
                                    var $bodyErrorMessage = $('.js-bodyErrorMessage' + bodies[j]['id']);
                                    if (bodies[j].error_code > 0) {
                                        $bodyErrorMessage.closest('.InputField').addClass('is-error');
                                        $bodyErrorMessage.html(bodies[j].error_message);
                                    }
                                    else {
                                        $bodyErrorMessage.closest('.InputField').removeClass('is-error');
                                    }
                                }
                            }
                        });
                    },
                };
            };
            return PresentView;
        }(Fily.BaseView));
        SP.PresentView = PresentView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
