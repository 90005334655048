var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var SignupView = (function (_super) {
            __extends(SignupView, _super);
            function SignupView() {
                return _super.call(this, {}) || this;
            }
            SignupView.prototype.events = function () {
                return {
                    'click / .js-signupButton': function (e) {
                        var $button = $(e.currentTarget);
                        var $form = $('#js-signupForm');
                        var href = $button.data('href');
                        if (href) {
                            $form.attr('action', href);
                        }
                        else {
                            e.preventDefault();
                            grecaptcha.ready(function () {
                                grecaptcha.execute(Fily.Config.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                                    var input = $("<input name='token' value=\"" + token + "\">").attr('hidden', 'true');
                                    $form.append(input);
                                    $form.submit();
                                });
                            });
                        }
                        setTimeout(function () {
                            $button.prop('disabled', true);
                            setTimeout(function () {
                                $button.prop('disabled', false);
                            }, 3000);
                        });
                    },
                    'focus / .js-hintInput': function (e) {
                        $(e.currentTarget).siblings('.js-hintView').addClass('is-active');
                    }
                };
            };
            return SignupView;
        }(Fily.BaseView));
        SP.SignupView = SignupView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
