var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var Induction = (function (_super) {
            __extends(Induction, _super);
            function Induction() {
                var _this = _super.call(this, { delegate: false }) || this;
                if (Induction._instance !== null) {
                    return Induction._instance;
                }
                _this.inductionClassName = '.js-induction';
                _this.inductionLinkClassName = '.js-inductionLink';
                _this.inductionCookie = {
                    footer: 'fily_inductionFooterOverlay'
                };
                _this.$siteFooter = _this.$body.find('#js-siteFooter');
                Induction._instance = _this;
                _this.setAllInductionAttributes();
                _this.delegateEvent();
                return _this;
            }
            Induction.prototype.events = function () {
                return {};
            };
            Induction.prototype.delegateEvent = function () {
                var _this = this;
                _super.prototype.delegateEvent.call(this);
                this.setInductionOverlayScrollEvent();
                this.$body.find('.js-inductionOverlayClose').on('click', function (event) {
                    var target = event.currentTarget;
                    Fily.Cookie.setItem("fily_induction" + $(target).data('overlay-type') + "Overlay", 'closed', {
                        expires: new Date(Date.now() + 60 * 60 * 24 * 1 * 1000).toUTCString()
                    });
                    $(target).parents(_this.inductionClassName).remove();
                    _this.$siteFooter.css('padding-bottom', 0);
                });
            };
            Induction.prototype.setAllInductionAttributes = function () {
                var _this = this;
                var $inductions = this.$body.find(this.inductionClassName);
                [].forEach.call($inductions, function (induction) {
                    _this.setAttributes($(induction), $(induction).find(_this.inductionLinkClassName));
                });
            };
            Induction.prototype.setAttributes = function ($target, $targetLink) {
                if (Fily.Util.Device.isLineBrowser() && Fily.Util.Device.isIOs()) {
                    $targetLink
                        .attr('data-action', $targetLink.data('iOSLINE導線'));
                }
                else if (Fily.Util.Device.isLineBrowser() && Fily.Util.Device.isAndroid()) {
                    $targetLink
                        .attr('data-action', $targetLink.data('AndroidLINE導線'));
                }
                else if (Fily.Util.Device.isIOs()) {
                    $targetLink
                        .attr('data-action', 'iOS導線');
                }
                else if (Fily.Util.Device.isAndroid()) {
                    $targetLink
                        .attr('data-action', 'Android導線');
                }
                else {
                    $target.remove();
                }
            };
            Induction.prototype.setInductionOverlayScrollEvent = function () {
                var _this = this;
                var $siteHeader = this.$body.find('#js-siteHeader');
                var $obi = this.$body.find('.js-obi');
                var siteHeaderHeight = $siteHeader.outerHeight();
                var $inductionFooterOverlay = this.$body.find('#js-inductionFooterOverlay');
                var $win = $(window);
                var siteHeaderPos = $siteHeader.offset().top;
                var currentTopPos = 0;
                if (Fily.Cookie.getItem(this.inductionCookie.footer) || $inductionFooterOverlay.length == 0) {
                    this.$siteFooter.css('padding-bottom', 0);
                    $win.on('scroll.inductionOverlay', function (event) {
                        currentTopPos = $win.scrollTop();
                        if (currentTopPos > 0) {
                            if (currentTopPos > siteHeaderPos) {
                                $siteHeader.attr('aria-hidden', 'true');
                                setTimeout(function () {
                                    $obi.css({
                                        display: 'block',
                                    });
                                    $obi.show();
                                }, 500);
                            }
                            else {
                                $siteHeader.attr('aria-hidden', 'false');
                                siteHeaderHeight = $siteHeader.outerHeight();
                                if (currentTopPos == 0) {
                                    _this.$body.find('[role=main]').css('padding-top', siteHeaderHeight);
                                }
                            }
                        }
                        siteHeaderPos = currentTopPos;
                    });
                }
                else {
                    $win.on('scroll.inductionOverlay', function (event) {
                        currentTopPos = $win.scrollTop();
                        if (currentTopPos > 0) {
                            if (currentTopPos > siteHeaderPos) {
                                $siteHeader.attr('aria-hidden', 'true');
                                setTimeout(function () {
                                    $obi.css({
                                        display: 'block',
                                    });
                                    $obi.show();
                                }, 500);
                                $inductionFooterOverlay.attr('aria-hidden', 'false');
                            }
                            else {
                                $siteHeader.attr('aria-hidden', 'false');
                                $inductionFooterOverlay.attr('aria-hidden', 'true');
                                siteHeaderHeight = $siteHeader.outerHeight();
                                _this.$body.find('[role=main]').css('padding-top', siteHeaderHeight);
                            }
                        }
                        siteHeaderPos = currentTopPos;
                    });
                }
            };
            Induction._instance = null;
            return Induction;
        }(Fily.BaseView));
        SP.Induction = Induction;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
