var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var UserView = (function (_super) {
            __extends(UserView, _super);
            function UserView() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            UserView.prototype.events = function () {
                return {
                    'click / .js-showModal--createIdea': function (event) {
                        event.preventDefault();
                        var $showModalCreateIdea = $(event.currentTarget);
                        var id = $showModalCreateIdea.data('id');
                        var type = $showModalCreateIdea.data('type');
                        var alias = $showModalCreateIdea.data('user-alias');
                        new IdeaCreateModalView(id, type, alias).show();
                    },
                    'change / .js-upload-photo': function (event) {
                        event.preventDefault();
                        if (Fily.Myself.isLogin()) {
                            SP.PhotoUploadView.show(true).done(function () {
                                SP.PhotoUploadView.render($(event.currentTarget));
                            });
                        }
                        else {
                            location.href = '/login/';
                        }
                    },
                    'change / .js-select-category': function (event) {
                        var $target = $(event.currentTarget);
                        if ($target.val() == '') {
                            Fily.Field.Err.show($target, Fily.ERR.CODE.SELECT_INVALID, ['カテゴリ']);
                        }
                        else {
                            Fily.Field.Err.hide($target);
                        }
                    }
                };
            };
            return UserView;
        }(Fily.BaseView));
        SP.UserView = UserView;
        var IdeaCreateModalView = (function (_super) {
            __extends(IdeaCreateModalView, _super);
            function IdeaCreateModalView(id, type, alias) {
                var _this = _super.call(this) || this;
                _this.id = id;
                _this.type = type;
                _this.alias = alias;
                _this.bodyTpl = new Fily.MustacheTpl('idea/create_modal');
                return _this;
            }
            IdeaCreateModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$title = $('form [name=title]', this.$body);
                this.titleCounter = new Fily.InputCounter(this.$title, $('.js-counter--ideatitle', this.$body));
                this.$category = $('form [name=category]', this.$body);
                this.$category.html(Fily.Categories.getInitialOption());
            };
            IdeaCreateModalView.prototype.isUnSelectedCategory = function () {
                return this.$category.val() == 0;
            };
            IdeaCreateModalView.prototype.done = function (event) {
                var _this = this;
                var $button = $(event.currentTarget).addClass('is-disabled');
                var categoryId = this.$category.val();
                if (!this.titleCounter.checkCount() || this.isUnSelectedCategory()) {
                    $button.removeClass('is-disabled').addClass('is-error');
                    if (!this.titleCounter.checkRequire()) {
                        Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_EMPTY, ['タイトル']);
                    }
                    else if (!this.titleCounter.checkLength()) {
                        Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['タイトル', this.titleCounter.maxLength.toString()]);
                    }
                    if (this.isUnSelectedCategory()) {
                        Fily.Field.Err.show(this.$category, Fily.ERR.CODE.SELECT_INVALID, ['カテゴリ']);
                    }
                    setTimeout(function () {
                        $button.removeClass('is-error');
                    }, 1000);
                    return;
                }
                Fily.showFullLoading();
                Fily.Api.Idea.create(this.$title.val(), categoryId, false, this.alias).done(function (res) {
                    location.href = '/idea/' + res['id'] + '/edit/';
                    _this.hide();
                }).fail(function (err) {
                    Fily.Ngword.checkInput([_this.$title]).done(function () { return alert(Fily.ERR.toString(Fily.ERR.CODE.FORM_ERROR)); });
                    $button.removeClass('is-disabled').addClass('is-error');
                    setTimeout(function () {
                        $button.removeClass('is-error');
                    }, 1000);
                }).always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaCreateModalView;
        }(Fily.ModalView));
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
