var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var SpecialistView = (function (_super) {
            __extends(SpecialistView, _super);
            function SpecialistView() {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.$jobCategorySelect = $('#js-specialistJobCategory');
                _this.$jobPanels = $('.js-jobPanel', _this.$body);
                _this.delegateEvent();
                $('#js-jobPanel-' + _this.$jobCategorySelect.data('controls').replace(/\s/g, '')).show();
                return _this;
            }
            SpecialistView.prototype.delegateEvent = function () {
                _super.prototype.delegateEvent.call(this);
            };
            SpecialistView.prototype.events = function () {
                var _this = this;
                return {
                    'change blur / #js-specialistJobCategory': function (e) {
                        if (!e.target.value) {
                            var area = $('#js-specialistArea').val();
                            var areaUrl = !area ? '/specialist/' : '/specialist/all/' + area;
                            if (areaUrl === location.pathname)
                                return;
                            return location.href = areaUrl;
                        }
                        else {
                            _this.$jobPanels.hide();
                            $('#js-jobPanel-' + _this.$jobCategorySelect.val()).show();
                        }
                    },
                    'change blur / .js-specialistJob': function (e) {
                        var newUrl = e.target.value;
                        if (newUrl === location.pathname)
                            return;
                        if (newUrl) {
                            location.href = newUrl;
                        }
                    },
                    'change blur / #js-specialistArea': function (e) {
                        var newUrl = $(e.target).data('specialist-url') + e.target.value;
                        if (newUrl === location.pathname)
                            return;
                        if (newUrl === '/specialist/all/' && location.pathname.replace(/\//g, '') === 'specialist')
                            return;
                        return location.href = newUrl;
                    }
                };
            };
            return SpecialistView;
        }(Fily.BaseView));
        SP.SpecialistView = SpecialistView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
