var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var IdeaEditInfoView = (function (_super) {
            __extends(IdeaEditInfoView, _super);
            function IdeaEditInfoView(ideaId) {
                var _this = _super.call(this, {}) || this;
                _this.ideaId = ideaId;
                _this.$body = $('.js-Idea-Info');
                var keywords = Array.prototype.map.call(_this.$body.find('.js-keyword'), function (dom) {
                    return {
                        id: $(dom).data('keyword-id'),
                        name: $(dom).data('keyword-name')
                    };
                });
                new IdeaEditInfoKeywordsView(ideaId, keywords);
                _this.MainPhotoView = new IdeaEditMainPhotoView(ideaId);
                return _this;
            }
            IdeaEditInfoView.prototype.events = function () {
                var _this = this;
                return {
                    'click / .js-showModal--editIdeaTitleInfo': function () {
                        new IdeaEditInfoTitleModalView(_this.ideaId, {
                            params: { title: _this.$body.find('.js-idea-title').html() },
                            size: Fily.ModalSize.Small
                        }).show();
                    },
                    'click / .js-showModal--editIdeaDescriptionInfo': function () {
                        new IdeaEditInfoDescriptionModalView(_this.ideaId, {
                            params: { description: _this.$body.find('.js-idea-description').html().replace(/<br>/g, '').trim() },
                        }).show();
                    },
                    'click / .js-showModal--editIdeaCategoryInfo': function () {
                        new IdeaEditInfoCategoryModalView(_this.ideaId, {
                            params: { categoryId: _this.$body.find('.js-idea-category').data('category-id') },
                            size: Fily.ModalSize.Small
                        }).show();
                    },
                    'click / .js-showModal--editIdeaKeywordsInfo': function () {
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', 'キーワードを設定する', '');
                        IdeaEditInfoKeywordsView.show();
                    },
                    'click / .js-showModal--editIdeaMainPhoto': function () {
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', 'メイン写真の設定', '');
                        IdeaEditMainPhotoView.show();
                        _this.MainPhotoView.getPhotoElement();
                    }
                };
            };
            return IdeaEditInfoView;
        }(Fily.BaseView));
        SP.IdeaEditInfoView = IdeaEditInfoView;
        var IdeaEditInfoTitleModalView = (function (_super) {
            __extends(IdeaEditInfoTitleModalView, _super);
            function IdeaEditInfoTitleModalView(ideaId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/info/title_modal');
                return _this;
            }
            IdeaEditInfoTitleModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$input = this.$body.find('textarea[name=title]');
                this.counter = new Fily.InputCounter(this.$input, this.$body.find('.js-counter'));
            };
            IdeaEditInfoTitleModalView.prototype.done = function () {
                var _this = this;
                if (!this.counter.checkRequire()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_EMPTY, ['タイトル']);
                    return;
                }
                else if (!this.counter.checkLength()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['タイトル', this.counter.maxLength.toString()]);
                    return;
                }
                var sendApi = Fily.Api.Idea.edit(this.ideaId, {
                    title: this.$input.val(),
                }).fail(function () { return Fily.Ngword.checkInput([_this.$input]); });
                Fily.showFullLoading();
                $.when(sendApi).done(function (res) {
                    $('.js-Idea-Info .js-idea-title').html(res['title']);
                    _this.hide();
                }).always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditInfoTitleModalView;
        }(Fily.ModalView));
        var IdeaEditInfoDescriptionModalView = (function (_super) {
            __extends(IdeaEditInfoDescriptionModalView, _super);
            function IdeaEditInfoDescriptionModalView(ideaId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/info/description_modal');
                return _this;
            }
            IdeaEditInfoDescriptionModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$input = this.$body.find('textarea[name=description]');
                this.counter = new Fily.InputCounter(this.$input, this.$body.find('.js-counter'));
            };
            IdeaEditInfoDescriptionModalView.prototype.done = function () {
                var _this = this;
                if (!this.counter.checkRequire()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_EMPTY, ['アイデアの概要・説明文']);
                    return;
                }
                else if (!this.counter.checkLength()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['アイデアの概要・説明文', this.counter.maxLength.toString()]);
                    return;
                }
                Fily.showFullLoading();
                var sendApi = Fily.Api.Idea.edit(this.ideaId, {
                    description: this.$input.val().trim(),
                }).fail(function () { return Fily.Ngword.checkInput([_this.$input]); });
                $.when(sendApi).done(function (res) {
                    $('.js-Idea-Info .js-idea-description').html(res['description']);
                    _this.hide();
                }).always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditInfoDescriptionModalView;
        }(Fily.ModalView));
        var IdeaEditInfoCategoryModalView = (function (_super) {
            __extends(IdeaEditInfoCategoryModalView, _super);
            function IdeaEditInfoCategoryModalView(ideaId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/info/category_modal');
                return _this;
            }
            IdeaEditInfoCategoryModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$category = this.$body.find('select[name=category]');
                this.$category.html(Fily.Categories.listHtml);
                this.$category.val(this.params.categoryId);
            };
            IdeaEditInfoCategoryModalView.prototype.done = function () {
                var _this = this;
                var sendApi = Fily.Api.Idea.edit(this.ideaId, {
                    category_id: this.$category.val(),
                });
                Fily.showFullLoading();
                var keywordItemTpl = new Fily.MustacheTpl('keyword/item');
                $.when(sendApi, keywordItemTpl.load()).done(function (res) {
                    $('.js-Idea-Info .js-idea-category').html(res['category']['name']).data('category-id', res['category']['id']);
                    $('.js-idea-keywords').html(res['keywords'].map(function (keyword) { return keywordItemTpl.render(keyword); }).join(''));
                    _this.hide();
                }).always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditInfoCategoryModalView;
        }(Fily.ModalView));
        var IdeaEditInfoKeywordsView = (function (_super) {
            __extends(IdeaEditInfoKeywordsView, _super);
            function IdeaEditInfoKeywordsView(ideaId, keywords) {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.ideaId = ideaId;
                _this.$body = $('#' + IdeaEditInfoKeywordsView.hash);
                _this.$list = $('.js-keyword-list', _this.$body);
                _this.itemTpl = new Fily.MustacheTpl('keyword/item');
                _this.editItemTpl = new Fily.MustacheTpl('keyword/edit/item');
                _this.emptyTpl = new Fily.MustacheTpl('keyword/edit/empty');
                _this.hasRecommend = false;
                _this.delegateEvent();
                _this.itemTpl.load();
                _this.editItemTpl.load();
                _this.emptyTpl.load();
                _this.$recommendKeyword = $('#js-recommendKeywords');
                _this.recommendKeywordTemplate = $('#js-recommendKeywordsTemplate').html();
                _this.render(keywords);
                _this.showRecommendKeywords($('.js-idea-category').data('english_name'));
                return _this;
            }
            IdeaEditInfoKeywordsView.show = function () {
                SP.changeHash(this.hash);
                SP.selectMainContents('#' + this.hash);
            };
            IdeaEditInfoKeywordsView.prototype.events = function () {
                var _this = this;
                return {
                    'click / .js-addKeyword': function () {
                        var $input = $('.js-keyword-input');
                        var keyword = $input.val().trim();
                        if (!keyword)
                            return;
                        if (Fily.Util.Str.isHalfwidthSymbol(keyword[0])) {
                            Fily.Field.Err.show($input, Fily.ERR.CODE.INPUT_KEYWORD_INVALID);
                            return;
                        }
                        else {
                            Fily.Field.Err.hide($input);
                        }
                        var MAX_KEYWORD_LENGTH = $input.data('maxlength');
                        if (keyword.length > MAX_KEYWORD_LENGTH) {
                            Fily.Field.Err.show($input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['キーワード', MAX_KEYWORD_LENGTH]);
                            return;
                        }
                        var MAX_KEYWORD_NUM = 30;
                        if (_this.keywordNum() >= MAX_KEYWORD_NUM) {
                            Fily.Field.Err.show($input, Fily.ERR.CODE.SET_MAX_ITEM_INVALID, ['キーワード', '30ワード']);
                            return;
                        }
                        _this.initRecommend();
                        if (_this.hasRecommend) {
                            _this.$recommendKeyword.find("[data-keyword-name=\"" + keyword + "\"]").addClass('is-added');
                        }
                        _this.append({ name: keyword }).done(function () { return $input.val(''); });
                    },
                    'click / .js-cancel': 'hide',
                    'click / .js-keyword-remove': 'removeKeyword',
                    'click / .js-keyword-add': 'addRecommendKeyword',
                };
            };
            IdeaEditInfoKeywordsView.prototype.render = function (keywords) {
                var _this = this;
                if (keywords === void 0) { keywords = []; }
                this.editItemTpl.load().done(function () {
                    _this.$list.html(keywords.map(function (keyword) { return _this.editItemTpl.render(keyword); }).join(''));
                    _this.renderIfEmpty();
                });
            };
            IdeaEditInfoKeywordsView.prototype.initRecommend = function () {
                this.hasRecommend = this.$recommendKeyword.length > 0;
            };
            IdeaEditInfoKeywordsView.prototype.append = function (keyword) {
                var _this = this;
                return this.save(keyword).done(function (res) {
                    _this.render(res['keywords']);
                });
            };
            IdeaEditInfoKeywordsView.prototype.renderIfEmpty = function () {
                var _this = this;
                if (this.keywordNum())
                    return;
                this.emptyTpl.load().done(function () {
                    _this.$list.html(_this.emptyTpl.render());
                });
            };
            IdeaEditInfoKeywordsView.prototype.getKeyword = function () {
                return $('.js-keyword-remove', this.$body);
            };
            IdeaEditInfoKeywordsView.prototype.keywordNum = function () {
                return this.getKeyword().length;
            };
            IdeaEditInfoKeywordsView.prototype.removeKeyword = function (e) {
                var _this = this;
                var $target = $(e.currentTarget);
                this.initRecommend();
                if (this.hasRecommend) {
                    this.$recommendKeyword.find('[data-keyword-id="' + $target.data('keyword-id') + '"]').removeClass('is-added');
                }
                $target.remove();
                this.save().done(function () {
                    _this.renderIfEmpty();
                });
            };
            IdeaEditInfoKeywordsView.prototype.addRecommendKeyword = function (e) {
                var $target = $(e.currentTarget);
                if ($target.hasClass('is-added'))
                    return;
                this.append({ name: $target.data('keyword-name') }).done(function () {
                    $target.addClass('is-added');
                });
            };
            IdeaEditInfoKeywordsView.prototype.hide = function () {
                SP.IdeaEditView.show();
            };
            IdeaEditInfoKeywordsView.prototype.keywords = function () {
                return Array.prototype.map.call($('.js-keyword'), function (dom) { return $(dom).data('keyword-name'); });
            };
            IdeaEditInfoKeywordsView.prototype.showRecommendKeywords = function (categoryEnglishName) {
                var _this = this;
                var template = this.recommendKeywordTemplate;
                var selectedKeywords = this.keywords();
                Fily.Api.Idea.getRecommendKeywords(categoryEnglishName)
                    .done(function (recommendKeywords) {
                    if (recommendKeywords) {
                        var words = recommendKeywords.map(function (key) {
                            if (selectedKeywords.indexOf(key.name) > -1) {
                                key.added = true;
                            }
                            return Mustache.render(template, key);
                        }).join('');
                        _this.$recommendKeyword.show().find('.js-keywordList').html(words);
                    }
                    else {
                        _this.$recommendKeyword.hide().find('.js-keywordList').html('');
                    }
                });
            };
            IdeaEditInfoKeywordsView.prototype.save = function (keyword) {
                var _this = this;
                if (keyword === void 0) { keyword = null; }
                var keywords = Array.prototype.map.call(this.getKeyword(), function (dom) { return $(dom).data('keyword-name'); });
                if (keyword)
                    keywords.push(keyword.name);
                Fily.showFullLoading(100);
                return Fily.Api.Idea.edit(this.ideaId, {
                    keywords: keywords,
                }, false)
                    .always(function () { return Fily.hideFullLoading(); })
                    .done(function (res) { return _this.itemTpl.load().done(function () {
                    if (res['keywords'].length > 0) {
                        $('.js-idea-keywords').html(res['keywords'].map(function (keyword) { return _this.itemTpl.render(keyword); }).join(''));
                    }
                    else {
                        $('.js-idea-keywords').html('<li data-textstyle="x-small lightgray">設定するとユーザーがアイデアを探しやすくなります。</li>');
                    }
                }); });
            };
            IdeaEditInfoKeywordsView.hash = 'edit-keywords';
            return IdeaEditInfoKeywordsView;
        }(Fily.BaseView));
        SP.IdeaEditInfoKeywordsView = IdeaEditInfoKeywordsView;
        var IdeaEditMainPhotoView = (function (_super) {
            __extends(IdeaEditMainPhotoView, _super);
            function IdeaEditMainPhotoView(ideaId) {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.ideaId = ideaId;
                _this.$body = $('#' + IdeaEditMainPhotoView.hash);
                _this.delegateEvent();
                _this.itemTemplate = $('#js-selectMainPhotoTemplate').html();
                _this.$mainPhotoSelector = $('#js-selectMainPhoto');
                _this.$mainPhotoLoader = $('#js-selectMainPhotoLoader');
                _this.getPhotoElement();
                return _this;
            }
            IdeaEditMainPhotoView.show = function () {
                SP.changeHash(this.hash);
                SP.selectMainContents('#' + this.hash);
            };
            IdeaEditMainPhotoView.prototype.getPhotoElement = function () {
                var _this = this;
                Fily.showLoading(this.$mainPhotoLoader);
                this.$mainPhotoSelector.html('');
                Fily.Api.Idea.get(this.ideaId).done(function (res) {
                    if (res) {
                        var mainElementId_1 = res.main_photo.id || null;
                        _this.photoUrls = res.elements.map(function (element) {
                            if (element.type === 3 && !element.content.is_external) {
                                return {
                                    element_id: element.id,
                                    urls: element.content.photo.photo_urls,
                                    selected: mainElementId_1 ? (mainElementId_1 === element.id) : false
                                };
                            }
                        }).filter(function (item) { return item; });
                        Fily.hideLoading(_this.$mainPhotoLoader);
                        _this.$mainPhotoSelector.html(_this.photoUrls.map(function (photo) { return Mustache.render(_this.itemTemplate, photo); }).join(''));
                    }
                });
            };
            IdeaEditMainPhotoView.prototype.events = function () {
                var _this = this;
                return {
                    'click / .js-add': function (e) {
                        Fily.showFullLoading();
                        var mainPhotoId = $(e.currentTarget).data('element-id');
                        console.assert(typeof mainPhotoId === 'number', 'could not get elementId');
                        Fily.Api.Idea.edit(_this.ideaId, {
                            main_photo_element_id: mainPhotoId,
                        }).done(function (res) {
                            console.assert(!!res.id, "fail to edit idea mainphoto: " + mainPhotoId);
                            if (res) {
                                $('.js-idea-mainPhoto').data('mainphoto-id', mainPhotoId);
                                $('#js-mainPhoto').attr('src', res.main_photo.content.photo.photo_urls['1691']);
                                _this.hide();
                                new Fily.Obi().show(false, 'メイン写真を変更しました。');
                            }
                        })
                            .always(function () { return Fily.hideFullLoading(); });
                    },
                    'click / .js-cancel': 'hide'
                };
            };
            IdeaEditMainPhotoView.prototype.hide = function () {
                SP.IdeaEditView.show();
            };
            IdeaEditMainPhotoView.hash = 'edit-mainphoto';
            return IdeaEditMainPhotoView;
        }(Fily.BaseView));
        SP.IdeaEditMainPhotoView = IdeaEditMainPhotoView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
