var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var UserFollowersView = (function (_super) {
            __extends(UserFollowersView, _super);
            function UserFollowersView() {
                var _this = _super.call(this, {}) || this;
                SP.StreamAutoLoader.init({
                    tpl: new Fily.MustacheTpl('stream/follow'),
                    limit: 20,
                    $loader: $('#js-loader'),
                    sender: function (offset, limit) {
                        return Fily.Api.User.Follower.get($('#js-hidden-data').data('alias'), offset, limit);
                    },
                    dataFormat: function (data) {
                        return { data: { follow: data } };
                    }
                });
                return _this;
            }
            return UserFollowersView;
        }(Fily.BaseView));
        SP.UserFollowersView = UserFollowersView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
