var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var Notification = (function (_super) {
            __extends(Notification, _super);
            function Notification() {
                var _this = _super.call(this) || this;
                if (SP.isNotification())
                    $('.js-show--notifications').click();
                return _this;
            }
            Notification.show = function () {
                Fily.Drawermenu.toggle(false);
                SP.changeHash(this.hash);
                SP.selectMainContents('#' + this.hash);
            };
            Notification.prototype.delegateEvent = function () {
                var _this = this;
                _super.prototype.delegateEvent.call(this);
                $(window).on('scroll', function (event) {
                    if (!SP.isNotification())
                        return;
                    var list = $('body').get(0);
                    if (list.scrollHeight - list.offsetHeight - 200 < list.scrollTop) {
                        if (Fily.Myself.isLogin()) {
                            if (_this.activities.$list.is(':visible'))
                                _this.loadNotifications(_this.activities);
                            if (_this.services.$list.is(':visible'))
                                _this.loadNotifications(_this.services);
                        }
                        else {
                            if (_this.services.$list.is(':visible'))
                                _this.loadNotifications(_this.services);
                        }
                    }
                });
            };
            Notification.prototype.toggleNotification = function () {
                var _this = this;
                Notification.show();
                setTimeout(function () { return _this.show(); }, 10);
            };
            Notification.prototype.getNotificationLink = function (notification) {
                if ('url' in notification)
                    return notification['url'];
                switch (parseInt(notification['type'])) {
                    case Fily.NotificationType.FOLLOW:
                        return '/user/' + notification['user']['alias'] + '/';
                    case Fily.NotificationType.COMMENT_MY_IDEA:
                    case Fily.NotificationType.RE_COMMENT_IDEA:
                    case Fily.NotificationType.COMMENT_COMMENTED_CONTENT_IDEA:
                    case Fily.NotificationType.COMMENT_MY_CONTENT_IDEA:
                    case Fily.NotificationType.RE_COMMENT_MY_COMMENT_IDEA:
                        return '/idea/' + notification['material_id'] + '/comments/';
                    case Fily.NotificationType.LIKE_MY_IDEA:
                    case Fily.NotificationType.CLIP_MY_IDEA:
                    case Fily.NotificationType.POST_IDEA:
                        return '/idea/' + notification['material_id'] + '/';
                    case Fily.NotificationType.LIKE_MY_PHOTO:
                    case Fily.NotificationType.CLIP_MY_PHOTO:
                    case Fily.NotificationType.POST_PHOTO:
                        return '/photo/' + notification['material_id'] + '/';
                    case Fily.NotificationType.COMMENT_MY_PHOTO:
                    case Fily.NotificationType.RE_COMMENT_PHOTO:
                    case Fily.NotificationType.COMMENT_COMMENTED_CONTENT_PHOTO:
                    case Fily.NotificationType.COMMENT_MY_CONTENT_PHOTO:
                    case Fily.NotificationType.RE_COMMENT_MY_COMMENT_PHOTO:
                        return '/photo/' + notification['material_id'] + '/comments/';
                    case Fily.NotificationType.LIKE_MY_COMMENT:
                    case Fily.NotificationType.LIKE_MY_COMMENT_IDEA:
                    case Fily.NotificationType.LIKE_MY_COMMENT_PHOTO:
                        return '/' + notification['target_content_type'] + '/' + notification['target_id'] + '/comments/';
                    default:
                        return '';
                }
            };
            Notification.hash = 'notifications';
            return Notification;
        }(Fily.Notification));
        SP.Notification = Notification;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
