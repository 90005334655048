var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var StreamAutoLoader = (function () {
            function StreamAutoLoader(params) {
                this.hasNext = true;
                this.isLoading = false;
                this.delegateEvent();
                this.$loader = params.$loader;
                this.$stream = $('#js-stream');
                this.offset = this.$stream.find('.Stream-item').length;
                this.limit = params.limit;
                this.sender = params.sender;
                this.tpl = params.tpl;
                if (params.dataFormat) {
                    this.dataFormat = params.dataFormat;
                }
                Fily.hideLoading(this.$loader);
            }
            StreamAutoLoader.init = function (params) {
                return new StreamAutoLoader(params);
            };
            StreamAutoLoader.prototype.delegateEvent = function () {
                var _this = this;
                $(window).on('scroll.streamload', function (event) {
                    var body = $('body').get(0);
                    if (body.scrollHeight - body.offsetHeight - 300 < document.scrollingElement.scrollTop) {
                        _this.loadmore();
                    }
                    ;
                });
            };
            StreamAutoLoader.prototype.loadmore = function () {
                var _this = this;
                if (!this.hasNext || this.isLoading)
                    return;
                this.isLoading = true;
                Fily.showLoading(this.$loader);
                $.when(this.sender(this.offset, this.limit), this.tpl.load()).done(function (res) {
                    if (!res)
                        res = [];
                    _this.$stream.append(res.map(function (data) { return _this.responseToRender(data); }).join(''));
                    if (res.length < _this.limit) {
                        _this.hasNext = false;
                        _this.$loader.hide();
                    }
                    ;
                    _this.offset += _this.limit;
                    _this.isLoading = false;
                });
            };
            StreamAutoLoader.prototype.dataFormat = function (data) {
                return data;
            };
            StreamAutoLoader.prototype.responseToRender = function (data) {
                return this.tpl.render(this.dataFormat(data));
            };
            return StreamAutoLoader;
        }());
        SP.StreamAutoLoader = StreamAutoLoader;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
