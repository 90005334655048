var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var PhotoUploadView = (function (_super) {
            __extends(PhotoUploadView, _super);
            function PhotoUploadView() {
                var _this = _super.call(this, {}) || this;
                _this.keywordTpl = new Fily.MustacheTpl('keyword/edit/item');
                _this.isContestPhoto = false;
                _this.contest10 = '1.改善したかった点\n2.こだわりポイント\n3.お住まいの種別(賃貸 or 持家)';
                _this.prepare();
                window.scrollTo(0, 0);
                return _this;
            }
            PhotoUploadView.show = function (isLoad) {
                var _this = this;
                if (isLoad === void 0) { isLoad = false; }
                if (!isLoad) {
                    SP.selectMainContents('#' + this.hash);
                    return;
                }
                Fily.showFullLoading();
                SP.changeHash(this.hash);
                return new Fily.MustacheTpl('photo/upload').load().done(function (tpl) {
                    $('#' + _this.hash).remove();
                    $('.js-UploadPhoto').after(tpl.render());
                    SP.selectMainContents('#' + _this.hash);
                    Fily.hideFullLoading();
                    Fily.Drawermenu.toggle(false);
                });
            };
            PhotoUploadView.render = function ($uploadPhoto) {
                this.instance = new this();
                if (this.instance) {
                    this.instance.$uploadPhoto = $uploadPhoto;
                }
                if ($uploadPhoto.prop('files')[0]) {
                    this.instance.renderPhoto($uploadPhoto);
                }
                return this.instance;
            };
            PhotoUploadView.prototype.events = function () {
                var _this = this;
                return {
                    'change / select[name=contest]': function (e) {
                        if (e.target.value === '10') {
                            _this.$comment.val(_this.contest10);
                        }
                        else if (_this.$comment.val() === _this.contest10) {
                            _this.$comment.val('');
                        }
                    },
                    'click / .js-post-photoarea': function () { return _this.$uploadPhoto.click(); },
                    'click / .js-keyword-add': 'addKeyword',
                    'click / .js-keyword-remove': 'removeKeyword',
                    'click / .js-done': 'done',
                    'click / .js-cancel': 'hide'
                };
            };
            PhotoUploadView.prototype.prepare = function () {
                var _this = this;
                this.$body = $('#' + PhotoUploadView.hash);
                this.$contest = $('select[name=contest]', this.$body);
                Fily.Api.Contest.getEnterablePhoto().done(function (res) {
                    if (!res) {
                        $("#" + _this.$contest.data('target-id')).remove();
                        _this.$contest = null;
                    }
                    else {
                        _this.isContestPhoto = true;
                        $("#" + _this.$contest.data('target-id')).show();
                        _this.$contest.append(res.map(function (c) { return "<option value=\"" + c.id + "\">" + c.title + "</option>"; }).join(''));
                        if (_this.$uploadPhoto.data('contest-id')) {
                            _this.$contest.val(_this.$uploadPhoto.data('contest-id'));
                        }
                        if (_this.$contest.val() === '10') {
                            _this.$comment.val(_this.contest10);
                        }
                    }
                });
                this.$uploadPhotoArea = $('.js-post-photoarea', this.$body);
                this.$comment = $('textarea[name=comment]', this.$body);
                this.$creditUrl = $('input[name=crediturl]', this.$body);
                this.$credit = $('input[name=credit]', this.$body);
                this.commentCounter = new Fily.InputCounter(this.$comment);
                this.creditUrlCounter = new Fily.InputCounter(this.$creditUrl, $('.js-counter--crediturl'));
                this.creditCounter = new Fily.InputCounter(this.$credit, $('.js-counter--credit'));
            };
            PhotoUploadView.prototype.renderPhoto = function ($uploadPhoto) {
                var _this = this;
                if (!this.uploader) {
                    this.uploader = new Fily.PhotoUploader($('.js-post-photoarea', this.$body));
                    var $button = $('#js-rotatePostPhoto');
                    $button.on('click', function () {
                        _this.uploader.rotatePreview();
                    });
                    $button.removeClass('is-inactive');
                }
                this.uploader.renderPhoto(this.$uploadPhoto.prop('files'));
            };
            PhotoUploadView.prototype.addKeyword = function () {
                var _this = this;
                var $input = $('input[name=keyword]');
                var keyword = $input.val().trim();
                if (!keyword)
                    return;
                if (Fily.Util.Str.isHalfwidthSymbol(keyword[0])) {
                    Fily.Field.Err.show($input, Fily.ERR.CODE.INPUT_KEYWORD_INVALID);
                    return;
                }
                else {
                    Fily.Field.Err.hide($input);
                }
                var MAX_KEYWORD_LENGTH = $input.data('maxlength');
                if (keyword.length > MAX_KEYWORD_LENGTH) {
                    Fily.Field.Err.show($input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['キーワード', MAX_KEYWORD_LENGTH]);
                    return;
                }
                var MAX_KEYWORD_NUM = 30;
                if (this.keywords().length >= MAX_KEYWORD_NUM) {
                    Fily.Field.Err.show($input, Fily.ERR.CODE.SET_MAX_ITEM_INVALID, ['キーワード', '30ワード']);
                    return;
                }
                this.keywordTpl.load().done(function () {
                    $('.js-keyword-list').append(_this.keywordTpl.render({ name: keyword }));
                    _this.toggleKeywordEmptyMessage();
                    $input.val('');
                });
            };
            PhotoUploadView.prototype.removeKeyword = function (event) {
                $(event.currentTarget).remove();
                this.toggleKeywordEmptyMessage();
            };
            PhotoUploadView.prototype.toggleKeywordEmptyMessage = function () {
                $('.js-keyword-empty').toggle(this.keywords().length == 0);
            };
            PhotoUploadView.prototype.keywords = function () {
                return Array.prototype.map.call($('.js-keyword-remove'), function (dom) { return $(dom).data('keyword-name'); });
            };
            PhotoUploadView.prototype.done = function (event) {
                var _this = this;
                var $button = $(event.currentTarget);
                if ($button.prop('disabled'))
                    return;
                if (this.checkError()) {
                    $button.addClass('is-error');
                    setTimeout(function () {
                        $button.removeClass('is-error');
                    }, 1000);
                    return;
                }
                $button.prop('disabled', true);
                Fily.showFullLoading();
                var isContestPhoto = this.$contest && this.$contest.val();
                this.uploader.rotateUploadPhoto().then(function () {
                    var api;
                    if (isContestPhoto) {
                        api = _this.upload(_this.$contest.val());
                    }
                    else {
                        api = _this.upload();
                    }
                    api.done(function (res) {
                        $button.removeClass('is-error');
                        if (isContestPhoto) {
                            if (Fily.Config.isIOSWebview) {
                                location.href = "/ios/contest/" + _this.$contest.val() + "/";
                            }
                            else {
                                location.href = "/contest/" + _this.$contest.val() + "/";
                            }
                        }
                        else {
                            location.href = '/user/' + Fily.Myself.alias + '/photo/';
                        }
                    }).fail(function () {
                        Fily.Ngword.checkInput([_this.$comment, _this.$credit]);
                        $button.removeClass('is-error');
                    }).always(function () {
                        $button.prop('disabled', false);
                        Fily.hideFullLoading();
                    });
                });
            };
            PhotoUploadView.prototype.upload = function (contestId) {
                if (contestId === void 0) { contestId = null; }
                if (contestId) {
                    return Fily.Api.Photo.upload({
                        data: this.uploader.uploadImageDataString,
                        description: this.$comment.val(),
                        keywords: this.keywords(),
                        credit_url: this.$creditUrl.val(),
                        credit_title: this.$credit.val(),
                        contest_id: contestId.toString()
                    });
                }
                else {
                    return Fily.Api.Photo.upload({
                        data: this.uploader.uploadImageDataString,
                        description: this.$comment.val(),
                        keywords: this.keywords(),
                        credit_url: this.$creditUrl.val(),
                        credit_title: this.$credit.val()
                    });
                }
            };
            PhotoUploadView.prototype.hide = function () {
                this.$uploadPhoto.replaceWith(this.$uploadPhoto.clone());
                history.back();
            };
            PhotoUploadView.prototype.checkError = function () {
                var invalid = false;
                if (!this.uploader.uploadImageDataString) {
                    Fily.Field.Err.show(this.$uploadPhotoArea, Fily.ERR.CODE.SELECT_INVALID, ['画像ファイル']);
                    invalid = true;
                }
                else if (!Fily.Util.checkImageFileSize(this.$uploadPhoto.prop('files')[0])) {
                    Fily.Field.Err.show(this.$uploadPhotoArea, Fily.ERR.CODE.IMAGE_SIZE_INVALID);
                    invalid = true;
                }
                if (!this.commentCounter.checkLength()) {
                    Fily.Field.Err.show(this.$comment, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['コメント', this.commentCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (this.$creditUrl.val() && !Fily.Util.isUrl(this.$creditUrl.val())) {
                    Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.INPUT_INVALID, ['出典（URL）']);
                    invalid = true;
                }
                else if (Fily.Denied.isDeniedPhotoUrl(this.$creditUrl.val())) {
                    Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.DENIED_PHOTO_URL);
                    invalid = true;
                }
                if (!this.creditUrlCounter.checkLength()) {
                    Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['出典（URL）', this.creditUrlCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (!this.creditCounter.checkLength()) {
                    Fily.Field.Err.show(this.$credit, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['クレジット', this.creditCounter.maxLength.toString()]);
                    invalid = true;
                }
                return invalid;
            };
            PhotoUploadView.hash = 'main-upload-photo';
            return PhotoUploadView;
        }(Fily.BaseView));
        SP.PhotoUploadView = PhotoUploadView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
