var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var TimelineView = (function (_super) {
            __extends(TimelineView, _super);
            function TimelineView() {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.$body = $('#js-timeline');
                _this.$followButton = $('.js-followButton', _this.$body);
                _this.delegateEvent();
                _this.setRecommendUsers();
                return _this;
            }
            TimelineView.prototype.delegateEvent = function () {
                _super.prototype.delegateEvent.call(this);
            };
            TimelineView.prototype.follow = function (list) {
                var _this = this;
                if (!Fily.Myself.isLogin()) {
                    location.href = '/signup/';
                    return;
                }
                Fily.showFullLoading();
                Fily.Api.User.follows(Fily.Myself.alias, list).done(function () { return _this.reload(); });
            };
            TimelineView.prototype.reload = function () {
                Fily.hideFullLoading();
                location.reload();
            };
            TimelineView.prototype.setRecommendUsers = function () {
                this.recommendUserList = [].map.call(this.$followButton, function (el) {
                    return {
                        id: $(el).data('user-id'),
                        alias: $(el).data('user-alias'),
                        following: false
                    };
                });
                return this;
            };
            TimelineView.prototype.updateRecommendUsers = function (alias, follow) {
                this.recommendUserList.filter(function (u) {
                    return u.alias === alias;
                })[0].following = follow;
                return this;
            };
            TimelineView.prototype.isFollowingRecommendUser = function () {
                return this.recommendUserList.filter(function (u) {
                    return u.following;
                }).length !== 0;
            };
            TimelineView.prototype.events = function () {
                var _this = this;
                return {
                    'click / .js-followAll': function () { return _this.follow(_this.recommendUserList.filter(function (u) { return !!u; })); },
                    'click / .js-followButton': function (e) {
                        if (!$(e.currentTarget).hasClass('is-following')) {
                            _this.updateRecommendUsers($(e.currentTarget).data('user-alias'), true);
                            $('.js-reload').removeClass('is-disabled');
                        }
                        else {
                            if (!_this.updateRecommendUsers($(e.currentTarget).data('user-alias'), false).isFollowingRecommendUser()) {
                                $('.js-reload').addClass('is-disabled');
                            }
                        }
                    },
                    'click / .js-reload': this.reload
                };
            };
            return TimelineView;
        }(Fily.BaseView));
        SP.TimelineView = TimelineView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
