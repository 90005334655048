var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var KeywordSuggestBodyView = (function (_super) {
            __extends(KeywordSuggestBodyView, _super);
            function KeywordSuggestBodyView() {
                var _this = _super.call(this, {}) || this;
                _this.tpl = new Fily.MustacheTpl('keyword/suggest_item');
                _this.$panel = $('#js-keywordSearchBody');
                _this.$input = $('#js-keywordSearchInputBody');
                _this.$suggest = $('#js-keywordSearchResultBody');
                _this.isSuggested = false;
                _this.isVisible = false;
                _this.$body.on('click', function (e) {
                    if (e.target.id !== 'js-keywordSearchInputBody') {
                        _this.closeSuggest();
                    }
                });
                return _this;
            }
            KeywordSuggestBodyView.prototype.events = function () {
                var _this = this;
                return {
                    'input paste propertychange / #js-keywordSearchBody': function (e) {
                        var val = e.target.value;
                        if (!val) {
                            _this.closeSuggest();
                            return;
                        }
                        ;
                        _this.showSuggest(val);
                    },
                    'mousedown / .js-suggestKeyword > a': function (e) {
                        e.stopPropagation();
                        _this.$input.val($(e.currentTarget).parent().data('word'));
                    },
                    'mousedown / #js-keywordSearchSubmitBody': function (e) {
                        _this.$input.parents('form').submit();
                    },
                    'mousedown / #js-keywordSearchShowBody': function (e) {
                        e.stopPropagation();
                        _this.toggle();
                    }
                };
            };
            KeywordSuggestBodyView.prototype.showSuggest = function (val) {
                var _this = this;
                $.when(Fily.Api.Keywords.suggest(val), this.tpl.load()).done(function (res) { return _this.renderSuggest(res); });
            };
            KeywordSuggestBodyView.prototype.renderSuggest = function (res) {
                this.$suggest.empty().append(this.tpl.render({ keywords: res.suggest.suggestions })).addClass('is-active');
                this.isSuggested = true;
            };
            KeywordSuggestBodyView.prototype.closeSuggest = function () {
                if (this.isSuggested) {
                    this.$suggest.empty().removeClass('is-active');
                }
            };
            KeywordSuggestBodyView.prototype.hide = function () {
                this.isVisible = false;
                this.closeSuggest();
                this.$input.val('');
                this.$panel.slideUp(150);
            };
            KeywordSuggestBodyView.prototype.show = function () {
                var _this = this;
                this.isVisible = true;
                this.$panel.slideDown(150, function () { return _this.$input.focus(); });
            };
            KeywordSuggestBodyView.prototype.toggle = function () {
                $('html').toggleClass('Modal-is-locked', !this.isVisible);
                if (this.isVisible) {
                    this.hide();
                }
                else {
                    this.show();
                }
            };
            return KeywordSuggestBodyView;
        }(Fily.BaseView));
        SP.KeywordSuggestBodyView = KeywordSuggestBodyView;
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
