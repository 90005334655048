var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var IdeaEditView = (function (_super) {
            __extends(IdeaEditView, _super);
            function IdeaEditView() {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.$body = $('.js-Idea');
                _this.elementTpl = new Fily.MustacheTpl('idea/edit/element');
                _this.$targetElement = $('.js-FieldEditor-target');
                _this.appliedContest = null;
                IdeaEditView.show();
                _this.ideaId = _this.$body.data('idea-id');
                _this.AddPhoto = new IdeaAddPhotoElementView(_this.ideaId, $.proxy(_this.addElement, _this));
                _this.delegateEvent();
                _this.elementTpl.load();
                new SP.IdeaEditInfoView(_this.ideaId);
                new SP.IdeaEditElementView(_this.ideaId);
                new IdeaAddTextElementView(_this.ideaId, $.proxy(_this.addElement, _this));
                new IdeaAddLinkElementView(_this.ideaId, $.proxy(_this.addElement, _this));
                _this.AddAffiliate = new IdeaAddAffiliateElementView(_this.ideaId);
                new IdeaAddMovieElementView(_this.ideaId, $.proxy(_this.addElement, _this));
                new IdeaAddSnsElementView(_this.ideaId, $.proxy(_this.addElement, _this));
                Fily.Api.Contest.getEnterableIdea(_this.ideaId).done(function (res) {
                    if (res) {
                        $('#js-contest-info').fadeIn();
                    }
                });
                Fily.Api.Contest.getPosted(_this.ideaId).done(function (res) {
                    if (res) {
                        _this.appliedContest = res;
                        $('#js-contest-title').show().find('p').html(res.title);
                    }
                });
                $.each(_this.$targetElement, function (index, val) {
                    var productElementType = $(val).data('element-type');
                    if (productElementType === Fily.Api.Idea.Element.Type.product) {
                        $(val).find('.js-edit-element').hide();
                    }
                });
                Fily.Insidemaps.init().setInsidemapsHeight();
                return _this;
            }
            IdeaEditView.show = function () {
                SP.changeHash('');
                SP.selectMainContents('#main');
            };
            IdeaEditView.prototype.events = function () {
                var _this = this;
                return {
                    'click / .js-showSetting': function () {
                        new IdeaEditSettingView(_this.ideaId, {
                            params: {
                                published: $('.js-idea-published', _this.$body).hasClass('is-published'),
                                enabledApplyContest: !_this.appliedContest,
                                commentRefusal: $('.js-idea-commentRefusal', _this.$body).hasClass('is-commentRefusal'),
                            },
                            doneCallback: function ($info, hide, res) {
                                var tpl = new Fily.MustacheTpl('idea/edit/info');
                                var keywordItemTpl = new Fily.MustacheTpl('keyword/item');
                                $.when(tpl.load(), keywordItemTpl.load()).done(function () {
                                    hide();
                                    $('.js-Idea-Info', _this.$body).html(tpl.render(res));
                                    $('.js-idea-keywords').html(res['keywords'].map(function (keyword) { return keywordItemTpl.render(keyword); }).join(''));
                                    Fily.Obi.init().show(false, '更新しました。');
                                    Fily.Api.Contest.getPosted(_this.ideaId).done(function (res) {
                                        if (!res && _this.appliedContest) {
                                            $('#js-contest-info').fadeIn();
                                        }
                                        else if (res) {
                                            _this.appliedContest = res;
                                            $('#js-contest-title').show().find('p').html(res.title);
                                        }
                                    });
                                });
                            }
                        }).show();
                    },
                    'click / .js-addElement': function (e) {
                        $(e.currentTarget).parent().prop('selected', true);
                    },
                    'click / .js-save--order': function () {
                        var elements = $.map($('.js-FieldEditor-target'), function (dom) { return $(dom).data('element-id'); });
                        Fily.showFullLoading();
                        Fily.Api.Idea.edit(_this.ideaId, { element_order: elements }).done(function () {
                            $('.js-bottomBar--order').removeClass('is-active');
                        }).always(function () { return Fily.hideFullLoading(); });
                    },
                    'click / .js-add--text': function (e) {
                        e.preventDefault();
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', '本文の追加', '');
                        IdeaAddTextElementView.show();
                    },
                    'change / .js-upload-photo--idea': function (e) {
                        e.preventDefault();
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', 'フォトの追加', '');
                        IdeaAddPhotoElementView.show();
                        _this.AddPhoto.render();
                        _this.AddPhoto.uploadPhoto(e);
                    },
                    'click / .js-add--link': function (e) {
                        e.preventDefault();
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', 'リンクボタンを追加', '');
                        IdeaAddLinkElementView.show();
                    },
                    'click / .js-add--affiliate': function (e) {
                        e.preventDefault();
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', '楽天商品の追加', '');
                        IdeaAddAffiliateElementView.show();
                        _this.AddAffiliate.setElementId(null);
                        _this.AddAffiliate.setDoneCallback($.proxy(_this.addElement, _this));
                    },
                    'click / .js-add-element--affiliateChild': function (e) {
                        e.preventDefault();
                        IdeaAddAffiliateElementView.show();
                        _this.AddAffiliate.setElementId($(event.target).parents('.js-FieldEditor-target').data('element-id'));
                        _this.AddAffiliate.setDoneCallback(function (res, elementHtml) {
                            $(e.target).parents('.js-FieldEditor-target').find('.js-Element').html(elementHtml);
                            setTimeout(function () {
                                Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                            }, 50);
                        });
                    },
                    'click / .js-add--movie': function (e) {
                        e.preventDefault();
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', '動画の追加', '');
                        IdeaAddMovieElementView.show();
                    },
                    'click / .js-add--sns': function (e) {
                        e.preventDefault();
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', 'instagramから追加', '');
                        IdeaAddSnsElementView.show();
                    },
                    'click / .js-delete--idea': function () {
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', 'アイデアを削除する', '');
                        _this.removeIdea();
                    },
                    'click / .js-save-idea': function (e) {
                        e.preventDefault();
                        if (Fily.Config.isIOSWebview) {
                            Fily.GA.sendEvent('操作', '編集を終了');
                            location.href = 'limia-bridge://pop';
                        }
                    }
                };
            };
            IdeaEditView.prototype.removeIdea = function () {
                if (window.confirm('このアイデアを削除しますが、よろしいですか？')) {
                    Fily.showFullLoading();
                    Fily.Api.Idea.remove(this.ideaId).done(function () {
                        if (Fily.Config.isIOSWebview) {
                            location.href = 'limia-bridge://pop?from=idea_edit';
                        }
                        else {
                            location.href = '/user/' + Fily.Myself.alias + '/';
                        }
                    }).always(function () { return Fily.hideFullLoading(); });
                }
            };
            IdeaEditView.prototype.addElement = function (res, elementHtml) {
                var $element = $(this.elementTpl.render(res));
                $element.find('.js-Element').html(elementHtml);
                var $selectedAddButton = $('.js-button--add-element:selected');
                $selectedAddButton.after($element);
                $selectedAddButton.prop('selected', false);
                setTimeout(function () {
                    Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                }, 50);
            };
            return IdeaEditView;
        }(Fily.BaseView));
        SP.IdeaEditView = IdeaEditView;
        var IdeaAddTextElementView = (function (_super) {
            __extends(IdeaAddTextElementView, _super);
            function IdeaAddTextElementView(ideaId, doneCallback) {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.ideaId = ideaId;
                _this.doneCallback = doneCallback;
                _this.$body = $('#' + IdeaAddTextElementView.hash);
                _this.render();
                _this.delegateEvent();
                return _this;
            }
            IdeaAddTextElementView.show = function () {
                SP.changeHash(this.hash);
                SP.selectMainContents('#' + this.hash);
            };
            IdeaAddTextElementView.prototype.events = function () {
                var _this = this;
                return {
                    'change blur / #js-selectTextType': function (event) {
                        $('.js-element-body', _this.$body).toggle(_this.isBody());
                        $('.js-element-title', _this.$body).toggle(!_this.isBody());
                    },
                    'click / .js-done': 'save',
                    'click / .js-cancel': 'hide',
                };
            };
            IdeaAddTextElementView.prototype.render = function () {
                var _this = this;
                this.$title = $('input[name=title]', this.$body);
                this.$titleField = this.$title.parents('.Field');
                this.titleCounter = new Fily.InputCounter(this.$title, $('.js-counter--title', this.$body), function (isOver) {
                    if (isOver) {
                        _this.$titleField.addClass('is-error');
                    }
                    else {
                        _this.$titleField.removeClass('is-error');
                    }
                });
                this.$text = $('textarea[name=body]', this.$body);
                this.$textField = this.$text.parents('.Field');
                this.textCounter = new Fily.InputCounter(this.$text, $('.js-counter--body', this.$body), function (isOver) {
                    if (isOver) {
                        _this.$textField.addClass('is-error');
                    }
                    else {
                        _this.$textField.removeClass('is-error');
                    }
                });
            };
            IdeaAddTextElementView.prototype.isBody = function () {
                return $('#js-selectTextType').val() === 'body';
            };
            IdeaAddTextElementView.prototype.save = function (event) {
                var _this = this;
                var $button = $(event.currentTarget).addClass('is-disabled');
                var pos = 0;
                var $selectedAddButton = $('.js-button--add-element:selected');
                pos = $selectedAddButton.next('.js-FieldEditor-target').index('.js-FieldEditor-target');
                if (pos < 0) {
                    pos = $('.js-Idea-Elements li').length;
                }
                var sendApi;
                var tpl;
                var elementType;
                var selectedTitleType = this.$body.find('#js-selectTextType').val();
                if (this.isBody()) {
                    if (!this.textCounter.checkCount()) {
                        if (!this.textCounter.checkRequire())
                            Fily.Field.Err.show(this.$text, Fily.ERR.CODE.INPUT_EMPTY, ['本文']);
                        if (!this.textCounter.checkLength())
                            Fily.Field.Err.show(this.$text, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['本文', this.textCounter.maxLength.toString()]);
                        $button.removeClass('is-disabled').addClass('is-error');
                        setTimeout(function () {
                            $button.removeClass('is-error');
                        }, 1000);
                        return;
                    }
                    sendApi = function () {
                        return Fily.Api.Idea.Element.create(_this.ideaId, Fily.Api.Idea.Element.Type.body, {
                            body: _this.$text.val()
                        }, pos).fail(function () { return Fily.Ngword.checkInput([_this.$text]); });
                    };
                    tpl = new Fily.MustacheTpl('idea/element/body');
                }
                else {
                    if (!this.titleCounter.checkCount()) {
                        if (!this.titleCounter.checkRequire())
                            Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_EMPTY, ['見出し']);
                        if (!this.titleCounter.checkLength())
                            Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['見出し', this.titleCounter.maxLength.toString()]);
                        $button.removeClass('is-disabled').addClass('is-error');
                        setTimeout(function () {
                            $button.removeClass('is-error');
                        }, 1000);
                        return;
                    }
                    if (selectedTitleType === 'title') {
                        elementType = Fily.Api.Idea.Element.Type.title;
                        tpl = new Fily.MustacheTpl('idea/element/title');
                    }
                    else if (selectedTitleType === 'subheadline') {
                        elementType = Fily.Api.Idea.Element.Type.subheadline;
                        tpl = new Fily.MustacheTpl('idea/element/subheadline');
                    }
                    else if (selectedTitleType === 'smallheadline') {
                        elementType = Fily.Api.Idea.Element.Type.smallheadline;
                        tpl = new Fily.MustacheTpl('idea/element/smallheadline');
                    }
                    sendApi = function () {
                        return Fily.Api.Idea.Element.create(_this.ideaId, elementType, {
                            title: _this.$title.val()
                        }, pos).fail(function () { return Fily.Ngword.checkInput([_this.$title]); });
                    };
                }
                Fily.showFullLoading();
                $.when(sendApi(), tpl.load()).done(function (res) {
                    _this.doneCallback(res, tpl.render(res));
                    $button.removeClass('is-disabled');
                    _this.hide();
                }).always(function () {
                    Fily.hideFullLoading();
                    $button.removeClass('is-disabled');
                });
            };
            IdeaAddTextElementView.prototype.hide = function () {
                this.refresh();
                IdeaEditView.show();
            };
            IdeaAddTextElementView.prototype.refresh = function () {
                $('#js-selectTextType', this.$body).val('body').trigger('change');
                this.$title.val('').trigger('input');
                this.$titleField.removeClass('is-error');
                this.$text.val('').trigger('input');
                this.$textField.removeClass('is-error');
            };
            IdeaAddTextElementView.hash = 'main-addtext';
            return IdeaAddTextElementView;
        }(Fily.BaseView));
        SP.IdeaAddTextElementView = IdeaAddTextElementView;
        var IdeaAddPhotoElementView = (function (_super) {
            __extends(IdeaAddPhotoElementView, _super);
            function IdeaAddPhotoElementView(ideaId, doneCallback) {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.ideaId = ideaId;
                _this.doneCallback = doneCallback;
                _this.$body = $('#' + IdeaAddPhotoElementView.hash);
                _this.delegateEvent();
                return _this;
            }
            IdeaAddPhotoElementView.show = function () {
                SP.changeHash(this.hash);
                SP.selectMainContents('#' + this.hash);
            };
            IdeaAddPhotoElementView.prototype.events = function () {
                var _this = this;
                return {
                    'click / .js-done': 'save',
                    'click / .js-cancel': 'hide',
                    'click / #js-rotatePhoto': function (e) {
                        _this.uploader.rotatePreview();
                    }
                };
            };
            IdeaAddPhotoElementView.prototype.render = function () {
                this.$creditUrl = $('input[name=crediturl]', this.$body);
                this.creditUrlCounter = new Fily.InputCounter(this.$creditUrl, $('.js-counter--crediturl', this.$body));
                this.$credit = $('input[name=credit]', this.$body);
                this.creditCounter = new Fily.InputCounter(this.$credit, $('.js-counter--credit', this.$body));
                var $preview = $('.js-upload-photoarea');
                this.uploader = new Fily.PhotoUploader($preview);
            };
            IdeaAddPhotoElementView.prototype.uploadPhoto = function (event) {
                this.$uploadPhoto = $(event.target);
                this.uploader.renderPhoto(this.$uploadPhoto.prop('files'));
                $('#js-rotatePhoto').removeClass('is-inactive');
            };
            IdeaAddPhotoElementView.prototype.save = function (event) {
                var _this = this;
                var $button = $(event.currentTarget);
                var $area = $('.js-upload-photoarea');
                if ($button.prop('disabled'))
                    return;
                var invalid = false;
                if (!this.uploader.uploadImageDataString) {
                    Fily.Field.Err.show($area, Fily.ERR.CODE.SELECT_INVALID, ['画像ファイル']);
                    invalid = true;
                }
                else if (!Fily.Util.checkImageFileSize(this.$uploadPhoto.prop('files')[0])) {
                    Fily.Field.Err.show($area, Fily.ERR.CODE.IMAGE_SIZE_INVALID);
                    invalid = true;
                }
                else {
                    Fily.Field.Err.hide($area);
                    $('.js-upload-photoarea').find('img').remove();
                    this.$uploadPhoto.val('');
                }
                if (this.$creditUrl.val() && !Fily.Util.isUrl(this.$creditUrl.val())) {
                    Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.INPUT_INVALID, ['出典（URL）']);
                    invalid = true;
                }
                else if (!this.creditUrlCounter.checkLength()) {
                    Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['出典（URL）', this.creditUrlCounter.maxLength.toString()]);
                    invalid = true;
                }
                else if (Fily.Denied.isDeniedPhotoUrl(this.$creditUrl.val())) {
                    Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.DENIED_PHOTO_URL);
                    invalid = true;
                }
                else {
                    Fily.Field.Err.hide(this.$creditUrl);
                }
                if (!this.creditCounter.checkLength()) {
                    Fily.Field.Err.show(this.$credit, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['クレジット', this.creditCounter.maxLength.toString()]);
                    invalid = true;
                }
                else {
                    Fily.Field.Err.hide(this.$credit);
                }
                if (invalid) {
                    $button.addClass('is-error');
                    setTimeout(function () {
                        $button.removeClass('is-error');
                    }, 1000);
                    return;
                }
                var pos = 0;
                var $selectedAddButton = $('.js-button--add-element:selected');
                pos = $selectedAddButton.next('.js-FieldEditor-target').index('.js-FieldEditor-target');
                if (pos < 0) {
                    pos = $('.js-Idea-Elements li').length;
                }
                this.uploader.rotateUploadPhoto().then(function () {
                    var sendApi = Fily.Api.Idea.Element.create(_this.ideaId, Fily.Api.Idea.Element.Type.photo, {
                        data: _this.uploader.uploadImageDataString,
                        credit_url: _this.$creditUrl.val(),
                        credit_title: _this.$credit.val()
                    }, pos);
                    var tpl = new Fily.MustacheTpl('idea/element/photo');
                    $button.prop('disabled', true);
                    Fily.showFullLoading();
                    $.when(sendApi, tpl.load())
                        .done(function (res) {
                        _this.doneCallback(res, tpl.render(res));
                        $button.removeClass('is-error');
                        _this.hide();
                    })
                        .fail(function () {
                        Fily.Ngword.checkInput([_this.$credit]);
                        $button.removeClass('is-error');
                    })
                        .always(function () {
                        $button.prop('disabled', false);
                        Fily.hideFullLoading();
                    });
                });
            };
            IdeaAddPhotoElementView.prototype.hide = function () {
                this.refresh();
                IdeaEditView.show();
            };
            IdeaAddPhotoElementView.prototype.refresh = function () {
                this.$credit.val('').trigger('input');
                this.$creditUrl.val('').trigger('input');
            };
            IdeaAddPhotoElementView.hash = 'main-addphoto';
            return IdeaAddPhotoElementView;
        }(Fily.BaseView));
        SP.IdeaAddPhotoElementView = IdeaAddPhotoElementView;
        var IdeaAddLinkElementView = (function (_super) {
            __extends(IdeaAddLinkElementView, _super);
            function IdeaAddLinkElementView(ideaId, doneCallback) {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.ideaId = ideaId;
                _this.doneCallback = doneCallback;
                _this.$body = $('#' + IdeaAddLinkElementView.hash);
                _this.render();
                _this.delegateEvent();
                return _this;
            }
            IdeaAddLinkElementView.show = function () {
                SP.changeHash(this.hash);
                SP.selectMainContents('#' + this.hash);
            };
            IdeaAddLinkElementView.prototype.events = function () {
                return {
                    'click / .js-done': 'save',
                    'click / .js-cancel': 'hide',
                };
            };
            IdeaAddLinkElementView.prototype.render = function () {
                this.$linkTitle = $('input[name=link_title]', this.$body);
                this.linkTitleCounter = new Fily.InputCounter(this.$linkTitle, $('.js-counter--linkTitle', this.$body));
                this.$linkUrl = $('input[name=link_url]', this.$body);
                this.linkUrlCounter = new Fily.InputCounter(this.$linkUrl, $('.js-counter--linkUrl', this.$body));
            };
            IdeaAddLinkElementView.prototype.save = function (event) {
                var _this = this;
                var $button = $(event.currentTarget);
                var invalid = false;
                if (!this.linkTitleCounter.checkRequire()) {
                    Fily.Field.Err.show(this.$linkTitle, Fily.ERR.CODE.INPUT_EMPTY, ['リンクのテキスト']);
                    invalid = true;
                }
                if (!this.linkTitleCounter.checkLength()) {
                    Fily.Field.Err.show(this.$linkTitle, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['リンクのテキスト', this.linkTitleCounter.maxLength.toString()]);
                    invalid = true;
                }
                var url = this.$linkUrl.val();
                if (!Fily.Util.isUrl(url)) {
                    Fily.Field.Err.show(this.$linkUrl, Fily.ERR.CODE.INPUT_FORMAT_INVALID, ['URL']);
                    invalid = true;
                }
                if (!this.linkUrlCounter.checkRequire()) {
                    Fily.Field.Err.show(this.$linkUrl, Fily.ERR.CODE.INPUT_EMPTY, ['URL']);
                    invalid = true;
                }
                if (!this.linkUrlCounter.checkLength()) {
                    Fily.Field.Err.show(this.$linkUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['URL', this.linkUrlCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (invalid) {
                    $button.addClass('is-error');
                    setTimeout(function () {
                        $button.removeClass('is-error');
                    }, 1000);
                    return;
                }
                else {
                    var pos_1 = 0;
                    var $selectedAddButton = $('.js-button--add-element:selected');
                    pos_1 = $selectedAddButton.next('.js-FieldEditor-target').index('.js-FieldEditor-target');
                    if (pos_1 < 0) {
                        pos_1 = $('.js-Idea-Elements li').length;
                    }
                    var sendApi = function () {
                        return Fily.Api.Idea.Element.create(_this.ideaId, Fily.Api.Idea.Element.Type.link, {
                            label: _this.$linkTitle.val(),
                            url: url
                        }, pos_1).fail(function () { return Fily.Ngword.checkInput([_this.$linkTitle]); });
                    };
                    var tpl_1 = new Fily.MustacheTpl('idea/element/link');
                    Fily.showFullLoading();
                    $.when(sendApi(), tpl_1.load()).done(function (res) {
                        _this.doneCallback(res, tpl_1.render(res));
                        _this.hide();
                    }).always(function () {
                        Fily.hideFullLoading();
                    });
                }
            };
            IdeaAddLinkElementView.prototype.hide = function () {
                this.refresh();
                IdeaEditView.show();
            };
            IdeaAddLinkElementView.prototype.refresh = function () {
                this.$linkTitle.val('').trigger('input');
                this.$linkTitle.parents('.Field').removeClass('is-error');
                this.$linkUrl.val('').trigger('input');
                this.$linkUrl.parents('.Field').removeClass('is-error');
            };
            IdeaAddLinkElementView.hash = 'main-addlink';
            return IdeaAddLinkElementView;
        }(Fily.BaseView));
        SP.IdeaAddLinkElementView = IdeaAddLinkElementView;
        var IdeaAddAffiliateElementView = (function (_super) {
            __extends(IdeaAddAffiliateElementView, _super);
            function IdeaAddAffiliateElementView(ideaId) {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.ideaId = ideaId;
                _this.$body = $("#" + IdeaAddAffiliateElementView.hash);
                _this.affiliateElementTpl = new Fily.MustacheTpl('idea/element/affiliate');
                _this.affiliateSerchKeyword = '';
                _this.affiliateCategoriesTpl = new Fily.MustacheTpl('idea/edit/select_affiliate_categories');
                _this.amazonItemStreamListTpl = new Fily.MustacheTpl('idea/edit/select_amazon_item_list');
                _this.amazonItemStreamGridTpl = new Fily.MustacheTpl('idea/edit/select_amazon_item_grid');
                _this.rakutenIchibaItemStreamListTpl = new Fily.MustacheTpl('idea/edit/select_rakutenichiba_item_list');
                _this.rakutenIchibaItemStreamGridTpl = new Fily.MustacheTpl('idea/edit/select_rakutenichiba_item_grid');
                _this.rakutenProductStreamListTpl = new Fily.MustacheTpl('idea/edit/select_rakuten_product_list');
                _this.rakutenProductStreamGridTpl = new Fily.MustacheTpl('idea/edit/select_rakuten_product_grid');
                _this.render();
                _this.delegateEvent();
                return _this;
            }
            IdeaAddAffiliateElementView.show = function () {
                SP.changeHash(this.hash);
                SP.selectMainContents("#" + this.hash);
            };
            IdeaAddAffiliateElementView.prototype.setElementId = function (elementId) {
                this.elementId = elementId;
            };
            IdeaAddAffiliateElementView.prototype.setDoneCallback = function (doneCallBack) {
                this.doneCallback = doneCallBack;
            };
            IdeaAddAffiliateElementView.prototype.events = function () {
                return {
                    'click / .js-affiliateTabItem': 'clickAffiliateTabItem',
                    'click / .js-affiliateSwitchmenuBtn': 'clickAffiliateSwitchmenuBtn',
                    'submit / .js-searchAffiliateItem': 'searchAffiliateItems',
                    'focus / .js-affiliateSearchInput': 'focusAffiliateSearchInput',
                    'blur / .js-affiliateSearchInput': 'blurAffiliateSearchInput',
                    'click / .js-streamItem': 'clickStreamItem',
                    'click / .js-done': 'save',
                    'click / .js-cancel': 'hide',
                    'click / .js-affiliateTabRakuten': function () {
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', '楽天商品の追加', '');
                    },
                    'click / .js-affiliateTabRakutenProduct': function () {
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', '価格比較ナビの追加', '');
                    },
                    'click / .js-affiliateTabAmazon': function () {
                        Fily.GA.setDimension('icr');
                        Fily.GA.sendEvent('操作', 'amazon商品の追加', '');
                    }
                };
            };
            IdeaAddAffiliateElementView.prototype.render = function () {
                this.$affiliateTitle = this.$body.find('#js-affiliateTitle');
                this.$affiliateTabmenu = this.$body.find('#js-affiliateTabmenu');
                this.$affiliateTabItemContents = this.$body.find('.js-affiliateTabItemContents');
                this.loadAffiliateCategories();
                this.$affiliatePostCancelButtons = this.$body.find('.js-affiliatePostCancelButtons');
                this.$affiliatePostCancelButtons.show();
                this.$done = this.$body.find('.js-done');
            };
            IdeaAddAffiliateElementView.prototype.clickAffiliateTabItem = function (event) {
                event.preventDefault();
                var $target = $(event.currentTarget);
                this.$affiliateTabmenu.find('.is-current').removeClass('is-current');
                $('.js-affiliateTabItem[aria-expanded=true]').attr('aria-expanded', 'false');
                this.$affiliateTabItemContents.hide();
                $target.parent().addClass('is-current');
                $target.attr('aria-expanded', $target.attr('aria-expanded') === 'true' ? 'false' : 'true');
                this.$affiliateTitle.text($target.text() + "\u5546\u54C1\u3092\u8FFD\u52A0\u3059\u308B");
                this.$body.find("#" + $target.attr('aria-controls')).find('.js-affiliateSearchInput').val(this.affiliateSerchKeyword);
                this.$body.find("#" + $target.attr('aria-controls')).show();
            };
            IdeaAddAffiliateElementView.prototype.focusAffiliateSearchInput = function (event) {
                event.preventDefault();
                this.$affiliatePostCancelButtons.hide();
            };
            IdeaAddAffiliateElementView.prototype.blurAffiliateSearchInput = function (event) {
                event.preventDefault();
                this.affiliateSerchKeyword = event.currentTarget.value;
                this.$affiliatePostCancelButtons.show();
            };
            IdeaAddAffiliateElementView.prototype.loadAffiliateCategories = function () {
                var _this = this;
                var affiliateSite;
                this.$affiliateTabItemContents.each(function (key, value) {
                    affiliateSite = parseInt($(value).data('site'), 10);
                    switch (affiliateSite) {
                        case Fily.Api.Idea.Element.AffiliateSite.amazon:
                            Fily.Api.Amazon.getSearchIndex().done(function (res) {
                                _this.renderAffiliateCategories($(value).find('.js-affiliateCategories'), 'カテゴリ', res.map(function (item) {
                                    return {
                                        index_name: item.index_name,
                                        display_name: item.display_name,
                                    };
                                }));
                            });
                            break;
                        case Fily.Api.Idea.Element.AffiliateSite.ichiba:
                        case Fily.Api.Idea.Element.AffiliateSite.rakuten:
                            Fily.Api.Rakuten.getIchibagenreSearch().done(function (res) {
                                _this.renderAffiliateCategories($(value).find('.js-affiliateCategories'), 'ジャンル', res.children.map(function (item) {
                                    return {
                                        index_name: item.child.genreId,
                                        display_name: item.child.genreName,
                                    };
                                }));
                            });
                            break;
                        default:
                            break;
                    }
                });
            };
            IdeaAddAffiliateElementView.prototype.renderAffiliateCategories = function ($target, defaultDisplayName, categories) {
                var _this = this;
                this.affiliateCategoriesTpl.load().done(function () {
                    $target.html(_this.affiliateCategoriesTpl.render({
                        default_display_name: defaultDisplayName,
                        categories: categories
                    }));
                });
            };
            IdeaAddAffiliateElementView.prototype.clickAffiliateSwitchmenuBtn = function (event) {
                event.preventDefault();
                var $target = $(event.currentTarget);
                var $targetAffiliateSwitchmenu = $target.parents('.js-affiliateSwitchmenu');
                var $targetStreamWrapper = $target.parents('.js-affiliateTabItemContents').find('.js-streamWrapper');
                $targetAffiliateSwitchmenu.find('.is-current').removeClass('is-current');
                $targetAffiliateSwitchmenu.find('.js-affiliateSwitchmenuBtn[aria-expanded=true]').attr('aria-expanded', 'false');
                $targetStreamWrapper.find('.js-stream').hide();
                $target.parent().addClass('is-current');
                $target.attr('aria-expanded', $target.attr('aria-expanded') === 'true' ? 'false' : 'true');
                $targetStreamWrapper.find("." + $target.attr('aria-controls')).show();
            };
            IdeaAddAffiliateElementView.prototype.searchAffiliateItems = function (event) {
                var _this = this;
                event.preventDefault();
                this.$done.prop('disabled', true);
                var $target = $(event.currentTarget);
                var $streamWrapper = $target.parent('header').next('.js-streamWrapper');
                var $streamList = $streamWrapper.find('.js-streamList');
                var $streamGrid = $streamWrapper.find('.js-streamGrid');
                var $loader = $streamWrapper.find('.js-loader');
                $streamList.empty();
                $streamGrid.empty();
                var site = parseInt($target.parents('.js-affiliateTabItemContents').data('site'), 10);
                var category = $target.find('[name="selectAffiliateCategories"]').val();
                var keyword = $target.find('[name="inputAffiliateSearch"]').val();
                if (keyword === '') {
                    $streamList.html(this.setDefaultMessage('Stream-item'));
                    $streamGrid.html(this.setDefaultMessage());
                    return false;
                }
                var error = null;
                var autoloaderParam = {
                    eventType: 'scrollWindowAndSync',
                    eventName: null,
                    limit: null,
                    offset: null,
                    isPage: true,
                    $targetWrapper: $(window),
                    $target: $streamList,
                    $syncTarget: $streamGrid,
                    $loader: $loader,
                    sender: null,
                    dataFormat: null,
                    tpl: null,
                    syncTpl: null,
                };
                switch (site) {
                    case Fily.Api.Idea.Element.AffiliateSite.amazon:
                        autoloaderParam.eventName = 'scroll.autoloadAmazon';
                        autoloaderParam.limit = IdeaAddAffiliateElementView.AMAZON_ITEM_LOAD_LIMIT;
                        autoloaderParam.offset = IdeaAddAffiliateElementView.AMAZON_ITEM_LOAD_PAGE;
                        autoloaderParam.sender = function (page, limit) {
                            return Fily.Api.Amazon.getItems(keyword, category, page);
                        };
                        autoloaderParam.dataFormat = function (data, limit, page) {
                            return {
                                items: _this.formatSearchItems(Fily.Api.Idea.Element.AffiliateSite.amazon, data, { page: page }),
                                length: data.list.length
                            };
                        };
                        autoloaderParam.tpl = this.amazonItemStreamListTpl;
                        autoloaderParam.syncTpl = this.amazonItemStreamGridTpl;
                        break;
                    case Fily.Api.Idea.Element.AffiliateSite.ichiba:
                        autoloaderParam.eventName = 'scroll.autoloadRakutenIchiba';
                        autoloaderParam.limit = IdeaAddAffiliateElementView.RAKUTEN_ICHIBA_ITEM_LOAD_HITS;
                        autoloaderParam.offset = IdeaAddAffiliateElementView.RAKUTEN_ICHIBA_ITEM_LOAD_PAGE;
                        autoloaderParam.sender = function (page, hits) {
                            return Fily.Api.Rakuten.getIchibaitemSearch(Number(category), keyword, 'standard', page);
                        };
                        autoloaderParam.dataFormat = function (data, hits, page) {
                            if (data.list.length < hits) {
                                error = { isLimitItem: true };
                            }
                            return {
                                items: _this.formatSearchItems(Fily.Api.Idea.Element.AffiliateSite.ichiba, data, { page: page }),
                                length: data.list.length,
                                error: error
                            };
                        };
                        autoloaderParam.tpl = this.rakutenIchibaItemStreamListTpl;
                        autoloaderParam.syncTpl = this.rakutenIchibaItemStreamGridTpl;
                        break;
                    case Fily.Api.Idea.Element.AffiliateSite.rakuten:
                        autoloaderParam.eventName = 'scroll.autoloadRakutenProduct';
                        autoloaderParam.limit = IdeaAddAffiliateElementView.RAKUTEN_PRODUCT_LOAD_HITS;
                        autoloaderParam.offset = IdeaAddAffiliateElementView.RAKUTEN_PRODUCT_LOAD_PAGE;
                        autoloaderParam.sender = function (page, hits) {
                            return Fily.Api.Rakuten.getProductSearch(Number(category), keyword, 'standard', page);
                        };
                        autoloaderParam.dataFormat = function (data, hits, page) {
                            if (data.Products.length < hits) {
                                error = { isLimitItem: true };
                            }
                            return {
                                products: _this.formatSearchItems(Fily.Api.Idea.Element.AffiliateSite.rakuten, data, { page: page }),
                                length: data.Products.length,
                                error: error
                            };
                        };
                        autoloaderParam.tpl = this.rakutenProductStreamListTpl;
                        autoloaderParam.syncTpl = this.rakutenProductStreamGridTpl;
                        break;
                    default:
                        break;
                }
                delete (this.affiliateAutoLoader);
                $(window).off(autoloaderParam.eventName);
                this.affiliateAutoLoader = Fily.AutoLoader.init(autoloaderParam);
                Fily.showLoading($loader);
                $.when(autoloaderParam.sender(1, 0), autoloaderParam.tpl.load(), autoloaderParam.syncTpl.load()).done(function (res) {
                    var streamItemParam;
                    switch (site) {
                        case Fily.Api.Idea.Element.AffiliateSite.amazon:
                            if (res.error) {
                                if (res.list.length === 0) {
                                    res.error.isParameterOutOfRange = false;
                                }
                                delete (_this.affiliateAutoLoader);
                                $(window).off(autoloaderParam.eventName);
                            }
                            streamItemParam = {
                                items: _this.formatSearchItems(Fily.Api.Idea.Element.AffiliateSite.amazon, res, { page: 1 })
                            };
                            break;
                        case Fily.Api.Idea.Element.AffiliateSite.ichiba:
                            if (res.list.length < IdeaAddAffiliateElementView.RAKUTEN_ICHIBA_ITEM_LOAD_HITS) {
                                error = (res.list.length === 0) ? { keyword: keyword, isNoExactMatches: true } : { isLimitItem: true };
                                delete (_this.affiliateAutoLoader);
                                $(window).off(autoloaderParam.eventName);
                            }
                            streamItemParam = {
                                items: _this.formatSearchItems(Fily.Api.Idea.Element.AffiliateSite.ichiba, res, { page: 1 }),
                                error: error
                            };
                            break;
                        case Fily.Api.Idea.Element.AffiliateSite.rakuten:
                            if (res.Products.length < IdeaAddAffiliateElementView.RAKUTEN_PRODUCT_LOAD_HITS) {
                                error = (res.Products.length === 0) ? { keyword: keyword, isNoExactMatches: true } : { isLimitItem: true };
                                delete (_this.affiliateAutoLoader);
                                $(window).off(autoloaderParam.eventName);
                            }
                            streamItemParam = {
                                products: _this.formatSearchItems(Fily.Api.Idea.Element.AffiliateSite.rakuten, res, { page: 1 }),
                                error: error
                            };
                            break;
                    }
                    $streamList.html(autoloaderParam.tpl.render(streamItemParam));
                    $streamGrid.html(autoloaderParam.syncTpl.render(streamItemParam));
                }).always(function () { return Fily.hideLoading($loader); });
            };
            IdeaAddAffiliateElementView.prototype.formatSearchItems = function (site, data, param) {
                var _this = this;
                switch (site) {
                    case Fily.Api.Idea.Element.AffiliateSite.amazon:
                        if (data.list.length === 0) {
                            return data;
                        }
                        data.list = data.list.map(function (value, index) {
                            if (value.price) {
                                value.suffix = param.page + "-" + index;
                                return value;
                            }
                            return value;
                        });
                        return data;
                    case Fily.Api.Idea.Element.AffiliateSite.ichiba:
                        if (data.list.length === 0) {
                            return data;
                        }
                        data.list.map(function (value, index) {
                            value.suffix = param.page + "-" + index;
                            value.Item.itemPrice = _this.formatPrice(value.Item.itemPrice);
                            return value;
                        });
                        return data;
                    case Fily.Api.Idea.Element.AffiliateSite.rakuten:
                        if (data.Products.length === 0) {
                            return [];
                        }
                        return data.Products.filter(function (item) { return item.mediumImageUrl !== ''; })
                            .map(function (value, index) {
                            value.suffix = param.page + "-" + index;
                            value.minPrice = _this.formatPrice(value.minPrice);
                            return value;
                        });
                }
            };
            IdeaAddAffiliateElementView.prototype.formatPrice = function (price) {
                return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            };
            IdeaAddAffiliateElementView.prototype.clickStreamItem = function (event) {
                event.preventDefault();
                var $target = $(event.currentTarget);
                var $targetStreamWrapper = $target.parents('.js-streamWrapper');
                this.affiliatePostParam = {
                    site: parseInt($target.data('site'), 10),
                    url: $target.data('url'),
                    title: $target.data('title'),
                    caption: $target.data('caption'),
                    description: $target.data('description'),
                    image: $target.data('image'),
                    thumbnail: $target.data('thumbnail'),
                };
                $targetStreamWrapper.find('.js-streamItemSelectBorder').removeClass('is-border');
                $targetStreamWrapper.find('.js-streamList').find("#js-streamItemList-" + $target.data('suffix')).find('.js-streamItemSelectBorder').addClass('is-border');
                $targetStreamWrapper.find('.js-streamGrid').find("#js-streamItemGrid-" + $target.data('suffix')).find('.js-streamItemSelectBorder').addClass('is-border');
                this.$done.prop('disabled', false);
            };
            IdeaAddAffiliateElementView.prototype.save = function (event) {
                var _this = this;
                event.preventDefault();
                var $target = $(event.currentTarget);
                if ($target.prop('disabled')) {
                    return false;
                }
                Fily.showFullLoading();
                if (this.elementId) {
                    Fily.Api.Idea.Element.createChild(this.ideaId, this.elementId, Fily.Api.Idea.Element.Type.affiliateLink, this.affiliatePostParam)
                        .done(function (res) {
                        _this.affiliateElementTpl.load().done(function () {
                            res.isAddChild = res.content.affiliate.length > 0 && res.content.affiliate.length < 2;
                            _this.doneCallback(res, _this.affiliateElementTpl.render(res));
                            _this.hide();
                            $target.prop('disabled', true);
                        }).always(function () { return Fily.hideFullLoading(); });
                    }).fail(function () { return Fily.hideFullLoading(); });
                }
                else {
                    var pos = 0;
                    var $selectedAddButton = $('.js-button--add-element:selected');
                    pos = $selectedAddButton.next('.js-FieldEditor-target').index('.js-FieldEditor-target');
                    if (pos < 0) {
                        pos = $('.js-Idea-Elements li').length;
                    }
                    Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.affiliate, {
                        affiliate: [
                            this.affiliatePostParam
                        ]
                    }, pos).done(function (res) {
                        _this.affiliateElementTpl.load().done(function () {
                            res.isAddChild = true;
                            _this.doneCallback(res, _this.affiliateElementTpl.render(res));
                            _this.hide();
                            $target.prop('disabled', true);
                        }).always(function () { return Fily.hideFullLoading(); });
                    }).fail(function () { return Fily.hideFullLoading(); });
                }
            };
            IdeaAddAffiliateElementView.prototype.hide = function () {
                this.refresh();
                IdeaEditView.show();
            };
            IdeaAddAffiliateElementView.prototype.refresh = function () {
                var _this = this;
                this.affiliatePostParam = {
                    site: null,
                    url: '',
                    title: '',
                    caption: '',
                    description: '',
                    image: '',
                    thumbnail: '',
                };
                delete (this.affiliateAutoLoader);
                $(window).off('scroll.autoloadAmazon');
                $(window).off('scroll.autoloadRakutenIchiba');
                $(window).off('scroll.autoloadRakutenProduct');
                this.$affiliateTabmenu.find('.is-current').removeClass('is-current');
                $('.js-affiliateTabItem[aria-expanded=true]').attr('aria-expanded', 'false');
                this.$affiliateTabmenu.find('[aria-controls=js-affiliateTabItemRakutenIchiba]').attr('aria-expanded', 'true');
                this.$affiliateTabmenu.find('[aria-controls=js-affiliateTabItemRakutenIchiba]').parent().addClass('is-current');
                this.$affiliateTabItemContents.hide();
                this.$affiliateTitle.text("\u697D\u5929\u5546\u54C1\u3092\u8FFD\u52A0\u3059\u308B");
                $('#js-affiliateTabItemRakutenIchiba').show();
                this.$affiliateTabItemContents.each(function (key, value) {
                    $(value).find('.js-searchAffiliateItem').find('[name=inputAffiliateSearch]').val('');
                    $(value).find('.js-searchAffiliateItem').find('[name=selectAffiliateCategories]').val('0');
                    $(value).find('.js-searchAffiliateItem').find('.js-affiliateSwitchmenu').find('[aria-controls=js-streamList]').click();
                    $(value).find('.js-streamWrapper').find('.js-streamList').empty().html(_this.setDefaultMessage('Stream-item'));
                    $(value).find('.js-streamWrapper').find('.js-streamGrid').empty().html(_this.setDefaultMessage());
                });
                this.$done.prop('disabled', true);
            };
            IdeaAddAffiliateElementView.prototype.setDefaultMessage = function (className) {
                if (className === void 0) { className = ''; }
                return "\n                <li class=\"" + className + "\" data-spacing=\"pt2\">\n                    <div class=\"u-textCenter\" data-textstyle=\"x-small lightgray\">\n                        \u5546\u54C1\u3092\u691C\u7D22\u3057\u3066\u3001\u5546\u54C1\u60C5\u5831\u3092\u8CBC\u308A\u4ED8\u3051\u3089\u308C\u307E\u3059\u3002\n                    </div>\n                </li>";
            };
            IdeaAddAffiliateElementView.hash = 'main-addaffiliate';
            IdeaAddAffiliateElementView.AMAZON_ITEM_LOAD_LIMIT = 10;
            IdeaAddAffiliateElementView.AMAZON_ITEM_LOAD_PAGE = 2;
            IdeaAddAffiliateElementView.RAKUTEN_ICHIBA_ITEM_LOAD_HITS = 28;
            IdeaAddAffiliateElementView.RAKUTEN_ICHIBA_ITEM_LOAD_PAGE = 2;
            IdeaAddAffiliateElementView.RAKUTEN_PRODUCT_LOAD_HITS = 28;
            IdeaAddAffiliateElementView.RAKUTEN_PRODUCT_LOAD_PAGE = 2;
            return IdeaAddAffiliateElementView;
        }(Fily.BaseView));
        SP.IdeaAddAffiliateElementView = IdeaAddAffiliateElementView;
        var IdeaAddMovieElementView = (function (_super) {
            __extends(IdeaAddMovieElementView, _super);
            function IdeaAddMovieElementView(ideaId, doneCallback) {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.ideaId = ideaId;
                _this.doneCallback = doneCallback;
                _this.$body = $('#' + IdeaAddMovieElementView.hash);
                _this.render();
                _this.delegateEvent();
                return _this;
            }
            IdeaAddMovieElementView.show = function () {
                SP.changeHash(this.hash);
                SP.selectMainContents('#' + this.hash);
            };
            IdeaAddMovieElementView.prototype.events = function () {
                return {
                    'click / .js-done': 'save',
                    'click / .js-cancel': 'hide',
                };
            };
            IdeaAddMovieElementView.prototype.render = function () {
                this.$movieUrl = this.$body.find('input[name=movie_url]');
                this.movieUrlCounter = new Fily.InputCounter(this.$movieUrl, this.$body.find('.js-counter--movieUrl'));
                this.$movieCaption = this.$body.find('textarea[name=movie_caption]');
                this.movieCaptionCounter = new Fily.InputCounter(this.$movieCaption, this.$body.find('.js-counter--movieCaption'));
            };
            IdeaAddMovieElementView.prototype.save = function (event) {
                var _this = this;
                var $button = $(event.currentTarget);
                var url = this.$movieUrl.val();
                var invalid = false;
                if (!Fily.Movie.Youtube.isValidUrl(url)) {
                    Fily.Field.Err.show(this.$movieUrl, Fily.ERR.CODE.MOVIE_URL_INVALID);
                    invalid = true;
                }
                if (!this.movieUrlCounter.checkRequire()) {
                    Fily.Field.Err.show(this.$movieUrl, Fily.ERR.CODE.INPUT_EMPTY, ['URL']);
                    invalid = true;
                }
                if (!this.movieUrlCounter.checkLength()) {
                    Fily.Field.Err.show(this.$movieUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['URL', this.movieUrlCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (!this.movieCaptionCounter.checkLength()) {
                    Fily.Field.Err.show(this.$movieCaption, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['説明文', this.movieCaptionCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (invalid) {
                    $button.addClass('is-error');
                    setTimeout(function () {
                        $button.removeClass('is-error');
                    }, 1000);
                    return;
                }
                else {
                    var pos_2 = 0;
                    var $selectedAddButton = $('.js-button--add-element:selected');
                    pos_2 = $selectedAddButton.next('.js-FieldEditor-target').index('.js-FieldEditor-target');
                    if (pos_2 < 0) {
                        pos_2 = $('.js-Idea-Elements li').length;
                    }
                    var sendApi = function () {
                        return Fily.Api.Idea.Element.create(_this.ideaId, Fily.Api.Idea.Element.Type.movie, {
                            movie_id: Fily.Movie.Youtube.getId(url),
                            movie_type: '1',
                            caption: _this.$movieCaption.val()
                        }, pos_2).fail(function () { return Fily.Ngword.checkInput([_this.$movieCaption]); });
                    };
                    var tpl_2 = new Fily.MustacheTpl('idea/element/movie');
                    Fily.showFullLoading();
                    $.when(sendApi(), tpl_2.load()).done(function (res) {
                        _this.doneCallback(res, tpl_2.render(res));
                        _this.hide();
                    }).always(function () {
                        Fily.hideFullLoading();
                    });
                }
            };
            IdeaAddMovieElementView.prototype.hide = function () {
                this.refresh();
                IdeaEditView.show();
            };
            IdeaAddMovieElementView.prototype.refresh = function () {
                this.$movieUrl.val('').trigger('input');
                this.$movieUrl.parents('.Field').removeClass('is-error');
                this.$movieCaption.val('').trigger('input');
                this.$movieCaption.parents('.Field').removeClass('is-error');
            };
            IdeaAddMovieElementView.hash = 'main-addmovie';
            return IdeaAddMovieElementView;
        }(Fily.BaseView));
        SP.IdeaAddMovieElementView = IdeaAddMovieElementView;
        var IdeaAddSnsElementView = (function (_super) {
            __extends(IdeaAddSnsElementView, _super);
            function IdeaAddSnsElementView(ideaId, doneCallback) {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.ideaId = ideaId;
                _this.doneCallback = doneCallback;
                _this.$body = $('#' + IdeaAddSnsElementView.hash);
                _this.render();
                _this.delegateEvent();
                return _this;
            }
            IdeaAddSnsElementView.show = function () {
                SP.changeHash(this.hash);
                SP.selectMainContents('#' + this.hash);
            };
            IdeaAddSnsElementView.prototype.events = function () {
                return {
                    'click / .js-done': 'save',
                    'click / .js-cancel': 'hide',
                };
            };
            IdeaAddSnsElementView.prototype.render = function () {
                this.$snsUrl = this.$body.find('input[name=sns_url]');
            };
            IdeaAddSnsElementView.prototype.save = function (event) {
                var _this = this;
                var $button = $(event.currentTarget);
                var snsUrl = this.$snsUrl.val();
                var snsType = Fily.Api.Idea.Element.SnsType.Instagram;
                var replacedSnsUrl = '';
                var invalid = false;
                if (!snsUrl) {
                    Fily.Field.Err.show(this.$snsUrl, Fily.ERR.CODE.INPUT_EMPTY, ['URL']);
                    invalid = true;
                }
                if (!Fily.Util.isUrl(snsUrl)) {
                    Fily.Field.Err.show(this.$snsUrl, Fily.ERR.CODE.INPUT_FORMAT_INVALID, ['URL']);
                    return;
                }
                if (!Fily.Sns.isValidSnsUrl(snsUrl, snsType)) {
                    Fily.Sns.showSnsInvalidUrlError(this.$snsUrl, snsType);
                    invalid = true;
                }
                else {
                    replacedSnsUrl = Fily.Sns.replaceSnsUrl(snsUrl, snsType);
                    if (!replacedSnsUrl) {
                        Fily.Sns.showSnsInvalidUrlError(this.$snsUrl, snsType);
                        invalid = true;
                    }
                }
                if (invalid) {
                    $button.addClass('is-error');
                    setTimeout(function () {
                        $button.removeClass('is-error');
                    }, 1000);
                    return;
                }
                else {
                    var pos_3 = 0;
                    var $selectedAddButton = $('.js-button--add-element:selected');
                    pos_3 = $selectedAddButton.next('.js-FieldEditor-target').index('.js-FieldEditor-target');
                    if (pos_3 < 0) {
                        pos_3 = $('.js-Idea-Elements li').length;
                    }
                    var sendApi = function () {
                        return Fily.Api.Idea.Element.create(_this.ideaId, Fily.Api.Idea.Element.Type.sns, {
                            sns_type: snsType,
                            url: replacedSnsUrl,
                        }, pos_3);
                    };
                    var tpl_3 = new Fily.MustacheTpl('idea/element/sns');
                    Fily.showFullLoading();
                    $.when(sendApi(), tpl_3.load()).done(function (res) {
                        _this.doneCallback(res, tpl_3.render(res));
                        if (snsType === Fily.Api.Idea.Element.SnsType.Instagram) {
                            Fily.Instagram.embed();
                        }
                        _this.hide();
                    }).always(function () {
                        Fily.hideFullLoading();
                    });
                }
            };
            IdeaAddSnsElementView.prototype.hide = function () {
                this.refresh();
                IdeaEditView.show();
            };
            IdeaAddSnsElementView.prototype.refresh = function () {
                this.$snsUrl.val('').trigger('input');
                this.$snsUrl.parents('.Field').removeClass('is-error');
            };
            IdeaAddSnsElementView.hash = 'main-addsns';
            return IdeaAddSnsElementView;
        }(Fily.BaseView));
        SP.IdeaAddSnsElementView = IdeaAddSnsElementView;
        var IdeaEditSettingView = (function (_super) {
            __extends(IdeaEditSettingView, _super);
            function IdeaEditSettingView(ideaId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/setting_modal');
                _this.selectableContests = [];
                return _this;
            }
            IdeaEditSettingView.prototype.render = function () {
                var _this = this;
                _super.prototype.render.call(this);
                this.$contest = $('select[name=contest_id]', this.$body);
                this.$publish = $('input[name=published]', this.$body);
                this.$commentRefusal = $('input[name=comment_refusal]', this.$body);
                this.defaultPublish = this.$publish.prop('checked');
                if (this.params.enabledApplyContest) {
                    Fily.Api.Contest.getEnterableIdea(this.ideaId).done(function (res) {
                        if (!res)
                            return;
                        _this.selectableContests = res || [];
                        $('#js-contest-select').show();
                        _this.$contest.append(res.map(function (c) { return "<option value=\"" + c.id + "\">" + c.title + "</option>"; }).join(''));
                    });
                }
            };
            IdeaEditSettingView.prototype.feedback = function ($button) {
                $button.removeClass('is-disabled');
                Fily.hideFullLoading();
            };
            IdeaEditSettingView.prototype.done = function (event) {
                var _this = this;
                var $button = $(event.currentTarget).addClass('is-disabled');
                var publish = this.$publish.prop('checked');
                var isCommentRefusal = this.$commentRefusal.prop('checked');
                var contestId = this.$contest.val();
                var params = this.params.enabledApplyContest ? {
                    publish: publish,
                    contest_id: contestId
                } : { publish: publish };
                var commentApi;
                if (isCommentRefusal) {
                    commentApi = Fily.Api.Idea.commentRefusal(this.ideaId)
                        .fail(function (e) {
                        if (e.status === 401) {
                            alert('正しいユーザーでログインしてください。');
                        }
                        else {
                            alert('コメント受付設定の変更ができませんでした。');
                        }
                        _this.$commentRefusal.prop('checked', !isCommentRefusal);
                    });
                }
                else {
                    commentApi = Fily.Api.Idea.commentAccept(this.ideaId)
                        .fail(function (e) {
                        if (e.status === 401) {
                            alert('正しいユーザーでログインしてください。');
                        }
                        else {
                            alert('コメント受付設定の変更ができませんでした。');
                        }
                        _this.$commentRefusal.prop('checked', isCommentRefusal);
                    });
                }
                ;
                var nextAction = contestId ? function (res) {
                    commentApi.done(function () {
                        if (Fily.Config.isIOSWebview) {
                            res['comment_refusal'] = isCommentRefusal;
                            return _super.prototype.done.call(_this, res);
                        }
                        else {
                            location.href = "/contest/" + contestId + "/";
                        }
                    });
                } : function (res) {
                    commentApi.done(function () {
                        res['comment_refusal'] = isCommentRefusal;
                        return _super.prototype.done.call(_this, res);
                    });
                };
                if (this.params.enabledApplyContest && !publish && !!contestId) {
                    if (window.confirm('イベントに応募するにはアイデアを公開する必要があります。\n公開しますか？')) {
                        Fily.showFullLoading();
                        Fily.Api.Idea.edit(this.ideaId, _.extend(params, {
                            contest_id: contestId,
                            publish: true,
                        }), publish != this.defaultPublish).done(nextAction)
                            .fail(function () {
                            alert('公開設定の変更ができませんでした。');
                            _this.$publish.prop('checked', !publish);
                        }).always(function () { return Fily.hideFullLoading(); });
                        return;
                    }
                    else {
                        this.feedback($button);
                        return;
                    }
                }
                Fily.showFullLoading();
                Fily.Api.Idea.edit(this.ideaId, params, publish != this.defaultPublish).done(nextAction)
                    .fail(function () {
                    alert('公開設定の変更ができませんでした。');
                    _this.$publish.prop('checked', !publish);
                }).always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditSettingView;
        }(Fily.ModalView));
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
