var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SP;
    (function (SP) {
        var IdeaEditElementView = (function (_super) {
            __extends(IdeaEditElementView, _super);
            function IdeaEditElementView(ideaId) {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.ideaId = ideaId;
                _this.targetClass = '.js-FieldEditor-target';
                _this.elementClass = '.js-Element';
                _this.targetAfterButtonClass = '.js-button--add-element';
                _this.delegateEvent();
                return _this;
            }
            IdeaEditElementView.prototype.events = function () {
                return {
                    'click / .js-move-up': 'moveUp',
                    'click / .js-move-down': 'moveDown',
                    'click / .js-edit-element': 'edit',
                    'click / .js-delete-element': 'remove'
                };
            };
            IdeaEditElementView.prototype.moveUp = function (event) {
                event.preventDefault();
                var $target = $(event.target).parents(this.targetClass);
                var $selectedAddButton = $target.next(this.targetAfterButtonClass).prop('selected', true);
                $target.prev(this.targetAfterButtonClass).prev(this.targetClass).before($target);
                $target.prev(this.targetAfterButtonClass).next(this.targetClass).after($selectedAddButton);
                $selectedAddButton.prop('selected', false);
                this.refreshElement($target);
                this.showSaveBar();
            };
            IdeaEditElementView.prototype.moveDown = function (event) {
                event.preventDefault();
                var $target = $(event.target).parents(this.targetClass);
                var $selectedAddButton = $target.next(this.targetAfterButtonClass).prop('selected', true);
                if ($target.next(this.targetAfterButtonClass).next(this.targetClass)[0]) {
                    $target.next(this.targetAfterButtonClass).next(this.targetClass).after($target);
                    $target.next(this.targetAfterButtonClass).prev(this.targetClass).before($selectedAddButton);
                }
                $selectedAddButton.prop('selected', false);
                this.refreshElement($target);
                this.showSaveBar();
            };
            IdeaEditElementView.prototype.showSaveBar = function () {
                $('.js-bottomBar--order').addClass('is-active');
            };
            IdeaEditElementView.prototype.edit = function (event) {
                var _this = this;
                event.preventDefault();
                var $target = $(event.target).parents(this.targetClass);
                var elementType = this.getElementType($target);
                var elementId = $target.data('element-id');
                if (elementType === Fily.Api.Idea.Element.Type.title) {
                    new IdeaEditTitleElementModalView(this.ideaId, elementId, {
                        params: {
                            title: $target.find('.js-Element-title').text()
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/title');
                            tpl.load().done(function () {
                                hide();
                                $target.find(_this.elementClass).html(tpl.render(res));
                            });
                        }
                    }).show();
                }
                else if (elementType === Fily.Api.Idea.Element.Type.subheadline) {
                    new IdeaEditTitleElementModalView(this.ideaId, elementId, {
                        params: {
                            title: $target.find('.js-Element-title').text()
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/subheadline');
                            tpl.load().done(function () {
                                hide();
                                $target.find(_this.elementClass).html(tpl.render(res));
                            });
                        }
                    }).show();
                }
                else if (elementType === Fily.Api.Idea.Element.Type.smallheadline) {
                    new IdeaEditTitleElementModalView(this.ideaId, elementId, {
                        params: {
                            title: $target.find('.js-Element-title').text()
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/smallheadline');
                            tpl.load().done(function () {
                                hide();
                                $target.find(_this.elementClass).html(tpl.render(res));
                            });
                        }
                    }).show();
                }
                else if (elementType == Fily.Api.Idea.Element.Type.body) {
                    new IdeaEditBodyElementModalView(this.ideaId, elementId, {
                        params: {
                            body: $('<div/>').html($target.find('.js-Element-body')
                                .html()
                                .replace(/<b>([\s\S]*?)<\/b>/g, '&lt;b&gt;$1&lt;&#x2F;b&gt;')
                                .replace(/<a .*?href="([\s\S]*?)".*?>([\s\S]*?)<\/a>/g, '&lt;a href=&quot;$1&quot;&gt;$2&lt;&#x2F;a&gt;')).text()
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/body');
                            tpl.load().done(function () {
                                hide();
                                $target.find(_this.elementClass).html(tpl.render(res));
                            });
                        }
                    }).show();
                }
                else if (elementType == Fily.Api.Idea.Element.Type.photo) {
                    new IdeaEditPhotoElementModalView(this.ideaId, elementId, {
                        params: {
                            caption: $target.find('.js-Element-photo--caption').data('photo-caption'),
                            photo_url: $target.find('.js-Element-photo--url').attr('src'),
                            is_avatar: $target.find('.js-Element-photo--avatar').data('is_avatar')
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/photo');
                            tpl.load().done(function () {
                                hide();
                                $target.find(_this.elementClass).html(tpl.render(res));
                                setTimeout(function () {
                                    Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                                }, 50);
                            });
                        }
                    }).show();
                }
                else if (elementType == Fily.Api.Idea.Element.Type.idea) {
                    new IdeaEditIdeaElementModalView(this.ideaId, elementId, {
                        params: {
                            idea_id: $target.find('.js-Element-idea--id').data('idea-id'),
                            idea_title: $target.find('.js-Element-idea--title').text(),
                            idea_nickname: $target.find('.js-Element-idea--nickname').text(),
                            idea_photo_url: $target.find('.js-Element-idea--photo').attr('src'),
                            idea_caption: $target.find('.js-Element-idea--caption').data('idea-caption')
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/idea');
                            tpl.load().done(function () {
                                hide();
                                $target.find(_this.elementClass).html(tpl.render(res));
                            });
                        }
                    }).show();
                }
                else if (elementType == Fily.Api.Idea.Element.Type.link) {
                    new IdeaEditLinkElementModalView(this.ideaId, elementId, {
                        params: {
                            link_title: $target.find('.js-Element-link').text(),
                            link_url: $target.find('.js-Element-link').data('external-url')
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/link');
                            tpl.load().done(function () {
                                hide();
                                $target.find(_this.elementClass).html(tpl.render(res));
                            });
                        }
                    }).show();
                }
                else if (elementType == Fily.Api.Idea.Element.Type.affiliate) {
                    new IdeaEditAffiliateElementModalView(this.ideaId, elementId, {
                        params: {
                            caption: $target.find('.js-Element-affiliate--caption').text(),
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/affiliate');
                            tpl.load().done(function () {
                                $target.find(_this.elementClass).html(tpl.render(res));
                                setTimeout(function () {
                                    Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                                }, 50);
                                hide();
                            });
                        }
                    }).show();
                }
                else if (elementType == Fily.Api.Idea.Element.Type.movie) {
                    new IdeaEditMovieElementModalView(this.ideaId, elementId, {
                        params: {
                            movie_id: $target.find('.js-Element-movie--id').data('movie-id'),
                            movie_caption: $target.find('.js-Element-movie--caption').data('movie-caption')
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/movie');
                            tpl.load().done(function () {
                                hide();
                                $target.find(_this.elementClass).html(tpl.render(res));
                            });
                        }
                    }).show();
                }
                else if (elementType == Fily.Api.Idea.Element.Type.sns) {
                    var snsType_1 = parseInt($('.js-Element-sns', $target).data('sns_type'), 10);
                    var snsUrl = $('.js-Element-sns', $target).data('url');
                    new IdeaEditSnsElementModalView(this.ideaId, elementId, {
                        params: {
                            sns_type: snsType_1,
                            sns_url: snsUrl,
                            sns_name: Fily.Api.Idea.Element.SnsType[snsType_1]
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/sns');
                            tpl.load().done(function () {
                                hide();
                                if (snsType_1 === Fily.Api.Idea.Element.SnsType.Instagram) {
                                    Fily.Instagram.embed();
                                }
                                $target.find(_this.elementClass).html(tpl.render(res));
                            });
                        }
                    }).show();
                }
                else if (elementType == Fily.Api.Idea.Element.Type.insidemaps) {
                    new IdeaEditInsidemapsElementModalView(this.ideaId, elementId, {
                        params: {
                            insidemaps_height: $target.find('.js-Element-insidemaps').attr('height'),
                            insidemaps_caption: $target.find('.js-Element-insidemaps--caption').text(),
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/insidemaps');
                            tpl.load().done(function () {
                                hide();
                                $target.find(_this.elementClass).html(tpl.render(res));
                            });
                        }
                    }).show();
                }
            };
            IdeaEditElementView.prototype.remove = function () {
                var $target = $(event.target).parents(this.targetClass);
                var mainPhotoId = $('.js-idea-mainPhoto').data('mainphoto-id');
                if ($target.data('element-id') === mainPhotoId) {
                    alert('メイン画像に登録されているため削除できません。選択の画像を削除したい場合はメイン画像を変更してください。');
                    return;
                }
                if (window.confirm('削除しますがよろしいですか？')) {
                    Fily.Api.Idea.Element.remove(this.ideaId, $target.data('element-id'));
                    $target.next().remove();
                    $target.remove();
                }
            };
            IdeaEditElementView.prototype.refreshElement = function ($target) {
                var elementType = this.getElementType($target);
                if (elementType == Fily.Api.Idea.Element.Type.sns) {
                    var $element = $('.js-Element-sns', $target);
                    var snsType = $element.data('sns_type');
                    if (snsType == Fily.Api.Idea.Element.SnsType.Twitter) {
                        var tweetId = $('iframe', $element).remove().data('tweet-id');
                        Fily.Twitter.embed(tweetId, $element.get(0));
                    }
                    ;
                }
            };
            IdeaEditElementView.prototype.getElementType = function ($target) {
                return parseInt($target.data('element-type'));
            };
            return IdeaEditElementView;
        }(Fily.BaseView));
        SP.IdeaEditElementView = IdeaEditElementView;
        var IdeaEditTitleElementModalView = (function (_super) {
            __extends(IdeaEditTitleElementModalView, _super);
            function IdeaEditTitleElementModalView(ideaId, elementId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.elementId = elementId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/element/title_modal');
                return _this;
            }
            IdeaEditTitleElementModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$input = this.$body.find('input[name=title]');
                this.counter = new Fily.InputCounter(this.$input, this.$body.find('.js-counter'));
            };
            IdeaEditTitleElementModalView.prototype.done = function () {
                var _this = this;
                if (!this.counter.checkRequire()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_EMPTY, ['見出し']);
                    return;
                }
                if (!this.counter.checkLength()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['見出し', this.counter.maxLength.toString()]);
                    return;
                }
                Fily.showFullLoading();
                Fily.Api.Idea.Element.edit(this.ideaId, this.elementId, {
                    title: this.$input.val(),
                }).done(function (res) { return _super.prototype.done.call(_this, res); })
                    .fail(function () { return Fily.Ngword.checkInput([_this.$input]); })
                    .always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditTitleElementModalView;
        }(Fily.ModalView));
        var IdeaEditBodyElementModalView = (function (_super) {
            __extends(IdeaEditBodyElementModalView, _super);
            function IdeaEditBodyElementModalView(ideaId, elementId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.elementId = elementId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/element/body_modal');
                return _this;
            }
            IdeaEditBodyElementModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$input = this.$body.find('textarea[name=body]');
                this.counter = new Fily.InputCounter(this.$input, this.$body.find('.js-counter'));
            };
            IdeaEditBodyElementModalView.prototype.done = function () {
                var _this = this;
                if (!this.counter.checkRequire()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_EMPTY, ['本文']);
                    return;
                }
                if (!this.counter.checkLength()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['本文', this.counter.maxLength.toString()]);
                    return;
                }
                Fily.showFullLoading();
                Fily.Api.Idea.Element.edit(this.ideaId, this.elementId, {
                    body: this.$input.val(),
                }).done(function (res) { return _super.prototype.done.call(_this, res); })
                    .fail(function () { return Fily.Ngword.checkInput([_this.$input]); })
                    .always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditBodyElementModalView;
        }(Fily.ModalView));
        var IdeaEditPhotoElementModalView = (function (_super) {
            __extends(IdeaEditPhotoElementModalView, _super);
            function IdeaEditPhotoElementModalView(ideaId, elementId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.elementId = elementId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/element/photo_modal');
                return _this;
            }
            IdeaEditPhotoElementModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$input = this.$body.find('textarea[name=caption]');
                this.counter = new Fily.InputCounter(this.$input, this.$body.find('.js-counter'));
            };
            IdeaEditPhotoElementModalView.prototype.done = function () {
                var _this = this;
                if (!this.counter.checkRequire()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_EMPTY, ['キャプション']);
                    return;
                }
                if (!this.counter.checkLength()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['キャプション', this.counter.maxLength.toString()]);
                    return;
                }
                Fily.showFullLoading();
                Fily.Api.Idea.Element.edit(this.ideaId, this.elementId, {
                    caption: this.$input.val(),
                }).done(function (res) {
                    res['content']['photo']['is_avatar'] = _this.params.is_avatar;
                    _super.prototype.done.call(_this, res);
                }).fail(function () { return Fily.Ngword.checkInput([_this.$input]); })
                    .always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditPhotoElementModalView;
        }(Fily.ModalView));
        var IdeaEditIdeaElementModalView = (function (_super) {
            __extends(IdeaEditIdeaElementModalView, _super);
            function IdeaEditIdeaElementModalView(ideaId, elementId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.elementId = elementId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/element/idea_modal');
                return _this;
            }
            IdeaEditIdeaElementModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$ideaCaption = this.$body.find('textarea[name=idea_caption]');
                this.ideaCaptionCounter = new Fily.InputCounter(this.$ideaCaption, this.$body.find('.js-counter'));
            };
            IdeaEditIdeaElementModalView.prototype.done = function () {
                var _this = this;
                if (!this.ideaCaptionCounter.checkLength()) {
                    Fily.Field.Err.show(this.$ideaCaption, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['説明文', this.ideaCaptionCounter.maxLength.toString()]);
                    return;
                }
                Fily.showFullLoading();
                Fily.Api.Idea.Element.edit(this.ideaId, this.elementId, {
                    caption: this.$ideaCaption.val()
                }).done(function (res) { return _super.prototype.done.call(_this, res); })
                    .fail(function () { return Fily.Ngword.checkInput([_this.$ideaCaption]); })
                    .always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditIdeaElementModalView;
        }(Fily.ModalView));
        var IdeaEditLinkElementModalView = (function (_super) {
            __extends(IdeaEditLinkElementModalView, _super);
            function IdeaEditLinkElementModalView(ideaId, elementId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.elementId = elementId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/element/link_modal');
                return _this;
            }
            IdeaEditLinkElementModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$linkTitleInput = this.$body.find('input[name=link_title]');
                this.linkTitleCounter = new Fily.InputCounter(this.$linkTitleInput, this.$body.find('.js-counter--linkTitle'));
                this.$linkUrlInput = this.$body.find('input[name=link_url]');
                this.linkUrlCounter = new Fily.InputCounter(this.$linkUrlInput, this.$body.find('.js-counter--linkUrl'));
            };
            IdeaEditLinkElementModalView.prototype.done = function () {
                var _this = this;
                if (!this.linkTitleCounter.checkRequire()) {
                    Fily.Field.Err.show(this.$linkTitleInput, Fily.ERR.CODE.INPUT_EMPTY, ['リンクのテキスト']);
                    return;
                }
                if (!this.linkTitleCounter.checkLength()) {
                    Fily.Field.Err.show(this.$linkTitleInput, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['リンクのテキスト', this.linkTitleCounter.maxLength.toString()]);
                    return;
                }
                var linkUrl = this.$linkUrlInput.val();
                if (!Fily.Util.isUrl(linkUrl)) {
                    Fily.Field.Err.show(this.$linkUrlInput, Fily.ERR.CODE.INPUT_FORMAT_INVALID, ['URL']);
                    return;
                }
                if (!this.linkUrlCounter.checkRequire()) {
                    Fily.Field.Err.show(this.$linkUrlInput, Fily.ERR.CODE.INPUT_EMPTY, ['URL']);
                    return;
                }
                if (!this.linkUrlCounter.checkLength()) {
                    Fily.Field.Err.show(this.$linkUrlInput, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['URL', this.linkUrlCounter.maxLength.toString()]);
                    return;
                }
                Fily.showFullLoading();
                Fily.Api.Idea.Element.edit(this.ideaId, this.elementId, {
                    label: this.$linkTitleInput.val(),
                    url: linkUrl
                }).done(function (res) { return _super.prototype.done.call(_this, res); })
                    .fail(function () { return Fily.Ngword.checkInput([_this.$linkTitleInput]); })
                    .always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditLinkElementModalView;
        }(Fily.ModalView));
        var IdeaEditAffiliateElementModalView = (function (_super) {
            __extends(IdeaEditAffiliateElementModalView, _super);
            function IdeaEditAffiliateElementModalView(ideaId, elementId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.elementId = elementId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/element/affiliate_modal');
                return _this;
            }
            IdeaEditAffiliateElementModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$input = this.$body.find('textarea[name=affiliate_description]');
                this.counter = new Fily.InputCounter(this.$input, this.$body.find('.js-counter'));
            };
            IdeaEditAffiliateElementModalView.prototype.done = function () {
                var _this = this;
                if (!this.counter.checkLength()) {
                    Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['説明文', this.counter.maxLength.toString()]);
                    return;
                }
                Fily.showFullLoading();
                Fily.Ngword.checkInput([this.$input]).done(function () {
                    Fily.Api.Idea.Element.edit(_this.ideaId, _this.elementId, {
                        caption: _this.$input.val()
                    }).done(function (res) {
                        res.isAddChild = res.content.affiliate.length > 0 && res.content.affiliate.length < 2;
                        _super.prototype.done.call(_this, res);
                    }).always(function () { return Fily.hideFullLoading(); });
                }).fail(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditAffiliateElementModalView;
        }(Fily.ModalView));
        var IdeaEditMovieElementModalView = (function (_super) {
            __extends(IdeaEditMovieElementModalView, _super);
            function IdeaEditMovieElementModalView(ideaId, elementId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.elementId = elementId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/element/movie_modal');
                return _this;
            }
            IdeaEditMovieElementModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$movieCaption = this.$body.find('textarea[name=movie_caption]');
                this.movieCaptionCounter = new Fily.InputCounter(this.$movieCaption, this.$body.find('.js-counter'));
            };
            IdeaEditMovieElementModalView.prototype.done = function () {
                var _this = this;
                if (!this.movieCaptionCounter.checkLength()) {
                    Fily.Field.Err.show(this.$movieCaption, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['説明文', this.movieCaptionCounter.maxLength.toString()]);
                    return;
                }
                Fily.showFullLoading();
                Fily.Api.Idea.Element.edit(this.ideaId, this.elementId, {
                    movie_id: this.params.movie_id,
                    movie_type: '1',
                    caption: this.$movieCaption.val()
                }).done(function (res) { return _super.prototype.done.call(_this, res); })
                    .fail(function () { return Fily.Ngword.checkInput([_this.$movieCaption]); })
                    .always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditMovieElementModalView;
        }(Fily.ModalView));
        var IdeaEditSnsElementModalView = (function (_super) {
            __extends(IdeaEditSnsElementModalView, _super);
            function IdeaEditSnsElementModalView(ideaId, elementId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.elementId = elementId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/element/sns_modal');
                return _this;
            }
            IdeaEditSnsElementModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$snsUrl = this.$body.find('input[name=sns_url]');
            };
            IdeaEditSnsElementModalView.prototype.done = function (event) {
                var _this = this;
                var $button = $(event.currentTarget);
                var snsType = this.params.sns_type;
                var snsUrl = this.$snsUrl.val();
                var replacedSnsUrl = '';
                var invalid = false;
                if (!snsUrl) {
                    Fily.Field.Err.show(this.$snsUrl, Fily.ERR.CODE.INPUT_EMPTY, ['URL']);
                    return;
                }
                if (!Fily.Util.isUrl(snsUrl)) {
                    Fily.Field.Err.show(this.$snsUrl, Fily.ERR.CODE.INPUT_FORMAT_INVALID, ['URL']);
                    return;
                }
                if (!Fily.Sns.isValidSnsUrl(snsUrl, snsType)) {
                    Fily.Sns.showSnsInvalidUrlError(this.$snsUrl, snsType);
                    invalid = true;
                }
                else {
                    replacedSnsUrl = Fily.Sns.replaceSnsUrl(snsUrl, snsType);
                    if (!replacedSnsUrl) {
                        Fily.Sns.showSnsInvalidUrlError(this.$snsUrl, snsType);
                        invalid = true;
                    }
                }
                if (invalid) {
                    $button.addClass('is-error');
                    setTimeout(function () {
                        $button.removeClass('is-error');
                    }, 1000);
                    return;
                }
                else {
                    Fily.showFullLoading();
                    Fily.Api.Idea.Element.edit(this.ideaId, this.elementId, {
                        sns_type: this.params.sns_type,
                        url: replacedSnsUrl,
                    }).done(function (res) { return _super.prototype.done.call(_this, res); })
                        .always(function () { return Fily.hideFullLoading(); });
                }
            };
            return IdeaEditSnsElementModalView;
        }(Fily.ModalView));
        var IdeaEditInsidemapsElementModalView = (function (_super) {
            __extends(IdeaEditInsidemapsElementModalView, _super);
            function IdeaEditInsidemapsElementModalView(ideaId, elementId, options) {
                var _this = _super.call(this, options) || this;
                _this.ideaId = ideaId;
                _this.elementId = elementId;
                _this.bodyTpl = new Fily.MustacheTpl('idea/edit/element/insidemaps_modal');
                return _this;
            }
            IdeaEditInsidemapsElementModalView.prototype.render = function () {
                _super.prototype.render.call(this);
                this.$insidempasCaption = this.$body.find('textarea[name=insidempas_caption]');
                this.insidemapsCaptionCounter = new Fily.InputCounter(this.$insidempasCaption, this.$body.find('.js-counter'));
            };
            IdeaEditInsidemapsElementModalView.prototype.done = function () {
                var _this = this;
                if (!this.insidemapsCaptionCounter.checkLength()) {
                    Fily.Field.Err.show(this.$insidempasCaption, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['説明文', this.insidemapsCaptionCounter.maxLength.toString()]);
                    return;
                }
                Fily.showFullLoading();
                Fily.Api.Idea.Element.edit(this.ideaId, this.elementId, {
                    caption: this.$insidempasCaption.val(),
                }).done(function (res) {
                    res['content']['insidemaps']['height'] = _this.params.insidemaps_height;
                    _super.prototype.done.call(_this, res);
                }).fail(function () { return Fily.Ngword.checkInput([_this.$insidempasCaption]); })
                    .always(function () { return Fily.hideFullLoading(); });
            };
            return IdeaEditInsidemapsElementModalView;
        }(Fily.ModalView));
    })(SP = Fily.SP || (Fily.SP = {}));
})(Fily || (Fily = {}));
