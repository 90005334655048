var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SampleView = (function (_super) {
        __extends(SampleView, _super);
        function SampleView(params) {
            return _super.call(this, params) || this;
        }
        SampleView.prototype.events = function () {
            return {
                'touchend click / .js-menuToggle': Fily.Drawermenu.toggle,
                'touchstart touchend touchmove / #js-closeDrawerMenu': Fily.Drawermenu.close,
                'mousedown / .js-like': 'toggleLike',
                'mousedown / .js-followButton': 'toggleFollow',
                'mousedown / .js-clip': 'clip'
            };
        };
        SampleView.prototype.delegateEvent = function () {
            _super.prototype.delegateEvent.call(this);
            Fily.SP.UserView.load();
            Fily.SP.delegateHashChangeEvent();
        };
        SampleView.prototype.toggleLike = function (event) {
            if (!Fily.Myself.isLogin()) {
                location.href = '/signup/';
                return;
            }
            event.stopPropagation();
            event.preventDefault();
            var $target = $(event.currentTarget);
            var id = $target.data('id');
            var type = $target.data('type');
            var $button = $('.js-like[data-id=' + id + '][data-type=' + type + ']');
            var flagClass = 'is-added';
            if ($button.hasClass(flagClass)) {
                $button.removeClass(flagClass);
                if (type == 'photo') {
                    Fily.Api.Photo.like(id, false).fail(function () { return $button.addClass(flagClass); });
                }
                else if (type == 'idea') {
                    Fily.Api.Idea.like(id, false).fail(function () { return $button.addClass(flagClass); });
                }
            }
            else {
                $button.addClass(flagClass);
                if (type == 'photo') {
                    Fily.Api.Photo.like(id).fail(function () { return $button.removeClass(flagClass); });
                }
                else if (type == 'idea') {
                    Fily.Api.Idea.like(id).fail(function () { return $button.removeClass(flagClass); });
                }
            }
        };
        SampleView.prototype.toggleFollow = function (event) {
            var followingClass = 'is-following';
            var $target = $(event.currentTarget);
            var id = $target.data('user-id');
            var alias = $target.data('user-alias');
            if (!alias || !Fily.Myself.isLogin()) {
                location.href = '/signup/';
                return false;
            }
            if ($target.hasClass(followingClass)) {
                Fily.Api.User.unfollow(alias).done(function (res) {
                    $target.removeClass(followingClass);
                });
            }
            else {
                Fily.Api.User.follow(alias, id).done(function (res) {
                    $target.addClass(followingClass);
                });
            }
        };
        SampleView.prototype.clip = function (event) {
            event.stopPropagation();
            event.preventDefault();
            if (!Fily.Myself.isLogin()) {
                location.href = '/signup/';
                return false;
            }
            var $target = $(event.currentTarget);
            var id = $target.data('id');
            var type = $target.data('type');
            new ClipModalView(id, type).show();
        };
        return SampleView;
    }(Fily.BaseView));
    Fily.SampleView = SampleView;
    var ClipModalView = (function (_super) {
        __extends(ClipModalView, _super);
        function ClipModalView(id, type) {
            var _this = _super.call(this, { size: 0 }) || this;
            _this.id = id;
            _this.type = type;
            _this.bodyTpl = new Fily.MustacheTpl('idea/select_modal');
            _this.delegateEvent();
            return _this;
        }
        ClipModalView.prototype.events = function () {
            return $.extend(_super.prototype.events.call(this), {
                'change / select[name=ideas]': 'clipSelectIdea'
            });
        };
        ClipModalView.prototype.show = function () {
            var _this = this;
            Fily.Api.Idea.getMyself(1000, 0, null, true).done(function (res) {
                _this.params = { ideas: res };
                _super.prototype.show.call(_this);
            });
        };
        ClipModalView.prototype.render = function () {
            var _this = this;
            _super.prototype.render.call(this);
            this.$ideaSelect = $('select[name=ideas]', this.$body);
            this.$title = $('input[name=title]', this.$body);
            this.$titleField = this.$title.parents('.Field');
            this.titleCounter = new Fily.InputCounter(this.$title, $('.js-counter--ideatitle', this.$body), function (isOver) {
                if (isOver) {
                    _this.$titleField.addClass('is-error');
                }
                else {
                    _this.$titleField.removeClass('is-error');
                }
            });
            this.$category = $('select[name=category]', this.$body);
            this.$category.html(Fily.Categories.getInitialOption());
        };
        ClipModalView.prototype.isSelectedNewIdea = function () {
            return this.$ideaSelect.val() == 'new';
        };
        ClipModalView.prototype.isUnSelectedIdea = function () {
            return this.$ideaSelect.val() == 0;
        };
        ClipModalView.prototype.isUnSelectedCategory = function () {
            return this.$category.val() == 0;
        };
        ClipModalView.prototype.clipSelectIdea = function (event) {
            var $target = $(event.currentTarget);
            var $newIdeaInfo = $('#js-create-clip-idea');
            if (this.isSelectedNewIdea()) {
                $newIdeaInfo.show();
                this.setSize(2);
            }
            else {
                $newIdeaInfo.hide();
                this.setSize(0);
                Fily.Field.Err.hide($target);
            }
            if ($target.val() == 0) {
                Fily.Field.Err.show($target, Fily.ERR.CODE.SELECT_INVALID, ['アイデア']);
            }
            else {
                Fily.Field.Err.hide($target);
            }
        };
        ClipModalView.prototype.done = function (event) {
            var _this = this;
            this.$button = $(event.currentTarget).addClass('is-disabled');
            if (this.isUnSelectedIdea()) {
                this.$button.removeClass('is-disabled').addClass('is-error');
                Fily.Field.Err.show(this.$ideaSelect, Fily.ERR.CODE.SELECT_INVALID, ['アイデア']);
                setTimeout(function () {
                    _this.$button.removeClass('is-error');
                }, 1000);
                return;
            }
            else if (this.isSelectedNewIdea()) {
                if (!this.titleCounter.checkCount() || this.isUnSelectedCategory()) {
                    this.$button.removeClass('is-disabled').addClass('is-error');
                    if (!this.titleCounter.checkRequire()) {
                        Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_EMPTY, ['タイトル']);
                    }
                    else if (!this.titleCounter.checkLength()) {
                        Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['タイトル', this.titleCounter.maxLength.toString()]);
                    }
                    if (this.isUnSelectedCategory()) {
                        Fily.Field.Err.show(this.$category, Fily.ERR.CODE.SELECT_INVALID, ['カテゴリ']);
                    }
                    setTimeout(function () {
                        _this.$button.removeClass('is-error');
                    }, 1000);
                    return;
                }
            }
            Fily.showFullLoading();
            if (this.isSelectedNewIdea()) {
                Fily.Api.Idea.create(this.$title.val(), this.$category.val()).done(function (res) {
                    _this.clip(res['id'], res['title']);
                }).fail(function () { return Fily.hideFullLoading(); });
            }
            else {
                this.clip(this.$ideaSelect.val(), this.$ideaSelect.find('option:selected').text());
            }
        };
        ClipModalView.prototype.clip = function (ideaId, ideaName) {
            var _this = this;
            var $button = this.$button;
            var sendApi;
            if (this.type == 'photo') {
                sendApi = Fily.Api.Photo.clip(ideaId, this.apiParams());
            }
            else {
                sendApi = Fily.Api.Idea.clip(ideaId, this.apiParams());
            }
            sendApi.done(function (res) {
                new Fily.Obi().show(false, ideaName + ' にクリップしました。');
                _this.hide();
                if ($button.hasClass('js-edit--idea')) {
                    location.href = '/idea/' + ideaId + '/edit/';
                }
            }).always(function () { return Fily.hideFullLoading(); });
        };
        ClipModalView.prototype.apiParams = function () {
            if (this.type == 'photo') {
                return {
                    photo_id: this.id,
                    layout: 1
                };
            }
            else if (this.type == 'idea') {
                return { idea_id: this.id };
            }
            return {};
        };
        return ClipModalView;
    }(Fily.ModalView));
    Fily.ClipModalView = ClipModalView;
})(Fily || (Fily = {}));
